.discountContainer {
    max-width: 400px;
    margin: 0 auto;
    background: #3aca79;
    border-radius: 8px;
    margin-top: 60px;
    position: relative;
    overflow: hidden;
    max-width: 400px;
}

@media only screen and (min-width: 768px) {
    .discountContainer {
        margin-top: 80px;
        max-width: initial;
    }
}

@media only screen and (min-width: 1440px) {
    .discountContainer {
        margin-top: 120px;
    }
}

.disctionDescriptionWrapper {
    width: 100%;
    padding: 20px 20px 0px 20px;
}

@media only screen and (min-width: 768px) {
    .disctionDescriptionWrapper {
        width: 43%;
        padding: 30px 0 30px 30px;
    }
}

/* @media only screen and (min-width: 1440px) {
    .disctionDescriptionWrapper {
    }
} */

.discountDescriptionTitle {
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);
    color: #ffffff;
}

.discountDescriptionText {
    font-weight: 400;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: white;
    margin-top: 10px;
}

.subscribeBtn {
    width: 234px;
    height: 45px;
    background: #ffffff;
    border-radius: 4px;
    font-weight: var(--font-weight-14-bold);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #3aca79;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.discountScreenshotWrapper {
    padding-top: 10px;
    margin-top: 10px;
    height: 100%;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
    .discountScreenshotWrapper {
        margin-top: initial;
        position: absolute;
        top: 0px;
        width: 77%;
        height: 100%;
        overflow: hidden;
        padding-left: 4%;
        padding-right: 3%;
        padding-top: 16px;
        left: 42%;
    }
}

@media only screen and (min-width: 1440px) {
    .discountScreenshotWrapper {
        position: absolute;
        padding-top: -13px;
        height: 100%;
        right: -11px;
        width: 58%;
        overflow: hidden;
        padding-left: 3%;
        padding-right: 3%;
    }
}

.discountScreenshot {
    content: url(./img/any/discount.png);
    box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
    transform: rotate(-2.84deg);

    width: 114%;
    max-width: 444px;
    margin-left: -30px;
}

@media only screen and (min-width: 768px) {
    .discountScreenshot {
        margin-left: initial;
        width: 100%;
        max-width: 639px;
        transform: rotate(-9.86deg);
    }
}
