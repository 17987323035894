.list {
    display: none;
    flex-wrap: wrap;
    background: #f8f8f8;
    padding: 0px 20px;
    position: initial;
    border-radius: 0 0 4px 4px;
}

@media only screen and (min-width: 660px) {
    .list {
        display: flex;
        background-color: initial;
        padding-left: 0px;
        border-radius: initial;
    }
}

.active.list {
    display: flex;
    position: absolute;
    z-index: 4;
}

.item {
    cursor: pointer;
    font-weight: 300;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    text-align: center;
    color: #221f1f;
    padding: 10px 15px;
    list-style-type: none;
    width: 100%;
    border-bottom: 1px solid #3aca794a;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 660px) {
    .item {
        text-align: initial;
        color: initial;
        padding: initial;
        list-style-type: initial;
        border-bottom: initial;
        margin-bottom: 10px;
        border-radius: 4px;
    }
}

.plus {
    content: url(./img/any/plus.svg);
}

.minus {
    content: url(./img/any/minus.svg);
}

.title {
    color: #221f1f;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    margin-left: 10px;
    width: 100%;
}

@media only screen and (min-width: 660px) {
    .title {
        width: max-content;
    }
}

.arrow {
    border: solid #3aca79;
    border-width: 0px 3px 3px 0;
    display: inline-block;
    padding: 4px;
    margin-bottom: 2px;
    margin-left: 5px;
    transform: rotate(45deg);
    transition: transform 0.5s;
}

.active .arrow {
    transform: rotate(-135deg);
    transition: transform 0.5s;
    margin-bottom: 0px;
}

.showCategoryList {
    position: relative;
    background: #d6f4e3;
    border-radius: 4px;
    height: 40px;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (min-width: 660px) {
    .showCategoryList {
        display: none;
    }
}

.showCategoryList.active {
    border-radius: 4px 4px 0 0;
    z-index: 4;
}

.categoryShowText {
    color: #221f1f;
    font-weight: var(--font-weight-12-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
}

.categoryWrapper {
    position: relative;
    width: 100%;
    display: inline-block;
}

@media only screen and (min-width: 660px) {
    .categoryWrapper {
        width: min-content;
        max-width: 50%;
    }
}
@media only screen and (min-width: 906px) {
    .categoryWrapper {
        max-width: 25%;
    }
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 200px;
}

.loader {
    width: 200px;
    height: 20px;
    background: rgb(212, 210, 210);
    background: linear-gradient(
        43deg,
        rgba(212, 210, 210, 1) 0%,
        rgba(136, 136, 136, 1) 50%,
        rgba(212, 210, 210, 1) 100%
    );
    animation: gradient 2s linear infinite;
    background-size: 200% 200%;
    border-radius: 4px;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.overlay.active {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000007a;
    top: 0;
    right: 0;
    z-index: 3;
}
