.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .notFound {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .notFound {
        margin-top: 30px;
    }
}

.chartWrapper {
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .chartWrapper {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .chartWrapper {
        margin-top: 30px;
    }
}

.warning {
    margin-top: 10px;
    line-height: 23px;
    font-weight: var(--font-weight-14);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #221f1f;
    white-space: pre-line;
}