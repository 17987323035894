.section {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .section {
        padding: 0 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .section {
        padding: 0 30px;
    }
}

.title {
    font-weight: 700;
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
}

.notViewed {
    background: #3ACA79;
    min-width: 4px;
    max-width: 4px;
    min-height: 4px;
    max-height: 4px;
    border-radius: 50%;
}

.notificationIcon {
    content: url(./img/any/notification-icon.svg);
    width: 18px;
    height: 100%;
    margin-left: 5px;
}

@media only screen and (min-width: 768px) {
    .notificationIcon {
        margin-left: 10px;
    }
}


.date {
    font-weight: 300;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: rgb(34 31 31 / 50%);
    margin-left: 5px;
}

@media only screen and (min-width: 1440px) {
    .date {
        margin-left: 10px;
    }
}

.message {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .message {
        margin-top: 15px;
    }
}

@media only screen and (min-width: 1440px) {
    .message {
        margin-top: 20px;
    }
}

.iconsContainer {
    display: flex;
    align-items: center;
}

.messageText {
    font-weight: 300;
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #221F1F;
    margin-left: 5px;
}

@media only screen and (min-width: 1440px) {
    .messageText {
        margin-left: 10px;
    }
}

.paginationWrapper {
    margin-top: 20px;
}

.noMessages {
    margin: 20px auto 0 auto;
}

@media only screen and (min-width: 768px) {
    .noMessages {
        margin: 30px auto 0 auto;
    }
}

@media only screen and (min-width: 1440px) {
    .noMessages {
        margin: 40px auto 0 auto;
    }
}