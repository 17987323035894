.section {
    margin-top: 60px;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .section {
        margin-top: 80px;
    }
}

@media only screen and (min-width: 1440px) {
    .section {
        margin-top: 120px;
    }
}

.sectionDivine {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100px;
    transform: translate(-50%, 0px);
    left: 50%;
    top: -10px;
    /* border-radius: 12px; */
    background: linear-gradient(360deg, white 0%, #f1f1f1 100%);
    z-index: -1;
}

@media only screen and (min-width: 768px) {
    .sectionDivine {
        top: -25px;
    }
}

@media only screen and (min-width: 1440px) {
    .sectionDivine {
        top: -30px;
    }
}


.header {
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);
    text-align: center;
    color: #221f1f;
    text-decoration: underline;
    text-decoration-color: #3aca79;
    text-underline-position: under;
}

.subHeader {
    margin-top: 10px;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    text-align: center;
    color: #221f1f;
}

.questionsContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media only screen and (min-width: 768px) {
    .questionsContainer {
        gap: 20px;
        margin-top: 40px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media only screen and (min-width: 1440px) {
    .questionsContainer {
        margin-top: 60px;
    }
}

.questionHeaderWrapper {
    display: flex;
    align-items: center;
}

.questionHeader {
    font-weight: 700;
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    color: #000107;
    margin-left: 20px;
}

.answer {
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #000107;
    margin-left: 40px;
    padding-right: 3vw;
    margin-top: 10px;
}

@media only screen and (min-width: 1024px) {
    .answer {
        margin-left: 50px;
    }
}

.question {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .question {
        margin-top: initial;
        width: 47%;
    }
}

.questionIcon {
    padding-top: 5px;
    content: url(./img/any/question-icon.svg);
    width: 20px;
}

@media only screen and (min-width: 1024px) {
    .questionIcon {
        width: 30px;
    }
}

.btnWrapper {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .btnWrapper {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .btnWrapper {
        margin-top: 30px;
    }
}
.answer a {
    color: #3aca79;
}
