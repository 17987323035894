.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(187, 186, 186, 0.733);
    backdrop-filter: blur(3px);
    z-index: 1;
    display: none;
}

.overlay.active {
    display: block;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    position: absolute;
    background: #fff;
    width: 315px;
    height: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

@media only screen and (min-width: 768px) {
    .wrapper {
        width: 500px;
    }
}

@media only screen and (min-width: 1440px) {
    .wrapper {
        width: 700px;
    }
}

.title {
    text-align: center;
    font-size: var(--font-size-24);
    line-height: var(--line-height-24);
    font-weight: var(--font-weight-24-bold);
}



.body {
    margin-top: 15px;
    text-align: center;
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    font-weight: var(--font-weight-10);
}

@media only screen and (min-width: 768px) {
    .body {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .body {
        margin-top: 25px;
    }
}