.wrapper {
    display: inline-block;
    position: relative;
    background: #f8f8f8;
    border-radius: 4px;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .wrapper {
    }
}

@media only screen and (min-width: 1440px) {
    .wrapper {
    }
}

.selected {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 10px;
}

.name {
    font-weight: 400;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
}


.arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    margin-bottom: 2px;
    margin-left: 5px;
    transform: rotate(45deg);
    transition: transform 0.5s;
}

.divider {
    display: block;
    margin: 0 5px;
    background-color: #eaeaea;
    height: 2px;
}

.optionWrapper {
    display: none;
    position: absolute;
    background-color: #f8f8f8;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
}

.option {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px 10px 4px 10px;
}

.active.wrapper {
    border-radius: 4px 4px 0 0;
}

.active .arrow {
    transform: rotate(-135deg);
    transition: transform 0.5s;
    margin-bottom: 0px;
}

.active .optionWrapper {
    display: block;
    top: calc(100% - 2px);
}