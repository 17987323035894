.input__field {
    box-sizing: border-box;
    width: 260px;
    height: 40px;
    border: 1px solid #221f1f80;
    border-radius: 4px;
    display: block;
    margin-bottom: 17px;
}

@media only screen and (min-width: 768px) {
    .input__field {
        width: 320px;
        height: 40px;
        margin-bottom: 19px;
    }
}

@media only screen and (min-width: 1440px) {
    .input__field {
        width: 400px;
        margin-bottom: 30px;
        height: 45px;
    }
}

.input__label {
    position: absolute;
    left: 16px;
    top: -6px;
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    font-weight: var(--font-weight-10-bold);
    background: white;
}

@media only screen and (min-width: 768px) {
    .input__label {
        top: -9px;
    }
}

@media only screen and (min-width: 1440px) {
    .input__label {
        top: -9px;
    }
}

.helperText {
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    font-weight: var(--font-weight-10);
    color: #ff6c6c;
    margin-top: -22px;
    margin-bottom: 20px;
    display: none;
}

.showError {
    display: block;
}