.news {
    padding-top: 15px;
    text-align: center;
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);
}

/* .containerNews {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.telegram {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.icoTelegram {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.icoTelegram img {
    width: 100%;
    height: 100%;
}

.telegramLink a {
    color: #3aca79;
    font-weight: var(--font-weight-14);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    text-decoration: underline;
}

.blockNews {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
    .blockNews {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .blockNews {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.imgNews {
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    height: fit-content;
}

.imgNews img {
    width: 100%;
    box-shadow: rgb(34 31 31 / 10%) 0px 0px 20px;
    border-radius: 10px;
}

@media only screen and (min-width: 768px) {
    .imgNews {
        width: 65%;
    }
}

.image1 {
    margin-bottom: 5%;
}

@media only screen and (min-width: 768px) {
    .image1 {
        margin-right: 5%;
        margin-bottom: 0;
    }
}

.flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.textNews {
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .textNews {
        width: 100%;
        margin-top: 0;
        margin-bottom: 10%;
    }
}

.titleNews {
    font-weight: var(--font-weight-16-bold);
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    color: #221f1f;
    text-align: center;
}

.dateNews {
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f75;
    margin-top: 5px;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .dateNews {
        margin-top: 10px;
    }
}

.descriptionNews {
    margin-top: 5px;
    font-weight: var(--font-weight-14);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
}

@media only screen and (min-width: 768px) {
    .descriptionNews {
        margin-top: 10px;
    }
}

.descriptionNews span {
    font-weight: var(--font-weight-14-bold);
}