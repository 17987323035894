.title {
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);

    text-align: center;
}

.questionIcon {
    content: url(./img/any/question-mark.png);
    width: 24px;
    margin-left: 10px;
    cursor: pointer;
}

.description {
    margin-top: 10px;
    line-height: 23px;
    font-weight: var(--font-weight-14);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #221f1f;
    white-space: pre-line;
}

.section {
    margin-top: 15px;
}


@media only screen and (min-width: 600px) {
    .section {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (min-width: 1440px) {
    .section {
        margin-top: 30px;
    }
}

