.section {
    margin-top: 35px;
}

@media only screen and (min-width: 768px) {
    .section {
        margin-top: 60px;
    }
}

@media only screen and (min-width: 1440px) {
    .section {
        margin-top: 44px;
    }
}
.title {
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);
    text-align: center;
    color: #221f1f;
}

.browsers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 35px;
}
@media only screen and (min-width: 768px) {
    .browsers {
        margin-top: 30px;
        margin-bottom: 60px;
    }
}

@media only screen and (min-width: 1440px) {
    .browsers {
        margin-top: 44px;
        margin-bottom: 100px;
    }
}

.chrome a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 100px;
}
.chrome img {
    width: 75px;
}
.edge a {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edge img {
    width: 75px;
}
.container {
    min-height: 100%;
}
.chromeDescr,
.edgeDescr {
    margin-top: 15px;
    font-weight: var(--font-weight-16-bold);
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    text-align: center;
    color: #221f1f;
}

.blockquote {
    margin: 0;
    color: #131314;
    padding: 30px 30px 30px 90px;
    position: relative;
    border-radius: 10px;
    box-shadow: rgb(34 31 31 / 10%) 0px 0px 20px;
}
.blockquote:before {
    content: '\201C';
    font-family: serif;
    position: absolute;
    top: 34%;
    left: 16px;
    color: #3aca79;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #3aca7929;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
}
.blockquote p {
    font-weight: var(--font-weight-14-medium);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
}
/* 
.limitSize {

}

@media only screen and (min-width: 768px) {
    .limitSize {
    }
}

@media only screen and (min-width: 1440px) {
    .limitSize {
        display: block;
        max-width: 35%;
    }
} */