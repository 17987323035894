.pushContainer {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    text-transform: none;
    font-style: normal;
    color: #221f1f;
    font-weight: var(--font-weight-10);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    height: 140px;
    display: flex;
    width: 260px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    margin-top: 15px;
}

@media only screen and (min-width: 768px) {
    .pushContainer {
        margin-top: 15px;
        width: 335px;
    }
}
@media only screen and (min-width: 1440px) {
    .pushContainer {
        margin-top: 0;
        width: 335px;
    }
}

.pushStatus {
    font-weight: var(--font-weight-10-bold);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    color: #221f1f;
    margin-bottom: 10px;
}
.pushStatusBtn {
    width: 125px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-weight-10-bold);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    border: none;
    border-radius: 4px;
    height: 35px;
    background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
    font-style: normal;
    color: #ffffff;
    padding: 12px 25px;
}

@media only screen and (min-width: 768px) {
    .pushStatusBtn {
        width: 150px;
    }
}

@media only screen and (min-width: 1440px) {
    .pushStatusBtn {
        width: 150px;
    }
}
.pushStatusBtn:hover {
    box-shadow: 0 5px 10px hsl(146deg 86% 63% / 49%);
}

.removeSub {
    background: linear-gradient(94.52deg, #e31010 0%, #ff9090 100%);
}
