.logo {
    content: url(./logo/logo.svg);
    width: 126px;
    /* height: 25px; */
    display: inline-block;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .logo {
        width: 169px;
        /* height: 40px; */
    }
}
@media only screen and (min-width: 1440px) {
    .logo {
        width: 228px;
        /* height: 54px; */
    }
}

.header {
    position: sticky;
    top: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    padding: 20px 20px 0px 20px;
}

@media only screen and (min-width: 768px) {
    .header {
    }
}
@media only screen and (min-width: 1440px) {
    .header {
        position: initial;
        width: 1140px;
        background-color: initial;
        /* padding: 50px 150px 0px 150px; */
        padding: 0px;
        padding-top: 50px;
        margin: 0 auto;
    }
}

.container {
    top: 0px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1140px;
}

@media only screen and (min-width: 768px) {
    .container {
        
    }
}
/* @media only screen and (min-width: 1140px) {
    .container {
        padding: 0px;
    }
} */

@media only screen and (min-width: 1440px) {
    .container {
        width: 1140px;
        position: initial;
    }
}

.logoLink {
    display: flex;
}