.dot {
    min-width: 4px;
    min-height: 4px;
    max-height: 4px;
    max-width: 4px;
    background: #3aca79;
    border-radius: 50%;
}

.outlineDot {
    min-width: 10px;
    min-height: 10px;
    max-height: 4px;
    max-width: 4px;
    border-radius: 50%;
    border: 0.5px solid #000107;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line {
    height: 100%;
    min-height: 5px;
    width: 1px;
    background: #000107;
    margin: 5px 0;
}

.step {
    color: #3aca79;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    transform: rotate(-90deg);
    width: fit-content;
}

@media only screen and (min-width: 768px) {
    .step {
        font-size: 14px;
    }
}
@media only screen and (min-width: 1440px) {
    .step {
        font-size: 15px;
    }
}

.stepWrapper {
    display: inline-block;
}

.stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-style: normal;
    font-weight: var(--font-weight-16);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #000107;
}

.subtitle {
    font-style: normal;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    letter-spacing: 0.1px;
    margin-top: 2px;
    color: #000107;
}

.card {
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-top: 10px;
    width: 100%;
}
@media only screen and (min-width: 768px) {
    .card {
        padding: 15px;
        margin-top: 15px;
    }
}
@media only screen and (min-width: 1440px) {
    .card {
        padding: 20px;
        margin-top: 20px;
    }
}

.content {
    margin-left: 10px;
}

.card:hover {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
    border-radius: 4px;
}

.hovered {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
    border-radius: 4px;
}