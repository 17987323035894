.section {
    margin-top: 20px;
    display: flex;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .section {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 1440px) {
    .section {
        margin-top: 40px;
    }
}

.input {
    width: 100%;
    height: 40px;
    border: 1px solid rgb(34 31 31 / 50%);
    padding: 10px 20px 10px 30px;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f;
    outline: none;
    border-radius: 4px 0px 0px 4px;
    border-right: none;
}

@media only screen and (min-width: 768px) {
    .input {
        height: 41px;
    }
}

@media only screen and (min-width: 1440px) {
    .input {
        height: 45px;
    }
}

.button {
    width: 56px;
    height: 40px;
    background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
    border-radius: 0px 4px 4px 0px;
    border: none;
    color: white;
    font-weight: var(--font-weight-10-bold);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    text-transform: uppercase;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .button {
        width: 94px;
        height: 40px;
        background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
        border-radius: 0px 4px 4px 0px;
        border: none;
        padding: 13px 25px;
        color: white;
        font-weight: var(--font-weight-10-bold);
        font-size: var(--font-size-10);
        line-height: var(--line-height-10);
        text-transform: uppercase;
        cursor: pointer;
        padding: 12px 25px;
        width: 103px;
        height: 41px;
    }
}

@media only screen and (min-width: 1440px) {
    .button {
        padding: 13px 30px;
        width: 121px;
        height: 45px;
    }
}

.showError {
    color: #F84147;
    font-weight: 400;
    font-size: var(--font-size-12);
}

.barcode {
    background: url('./img/any/barcode.svg') no-repeat center center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 100px;
    filter: contrast(0.5);
    right: 50px;
}

@media only screen and (min-width: 768px) {
    .barcode {
        right: 105px;
    }
}

@media only screen and (min-width: 1440px) {
    .barcode {
        right: 125px;
        top: 2px;
    }
}

.videoWrapper {
    display: block;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    max-height: 100%;
    max-width: 100%;
    overflow: clip;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.videoMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 20px;
    background-color: white;
    justify-content: space-between;
}

@media only screen and (min-width: 1440px) {
    .videoMenu {
        padding: 50px
    }
}


.videoMenuClose {
    background: url('./img/any/back.svg') no-repeat center center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
    right: 100px;
    filter: contrast(0.5);
    /* position: absolute; */
    /* left: 20px; */
}

@media only screen and (min-width: 768px) {
    .videoMenuClose {
        background-size: 40px 40px;
        height: 40px;
        width: 40px;
    }
}

@media only screen and (min-width: 1440px) {
    .videoMenuClose {
        background-size: 50px 50px;
        height: 50px;
        width: 50px;
    }
}


.videoMenuFlashlight {
    background: url('./img/any/flashlight.svg') no-repeat center center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
    right: 100px;
    filter: contrast(0.5);
    /* position: absolute; */
    /* left: 20px; */
}

@media only screen and (min-width: 768px) {
    .videoMenuFlashlight {
        background-size: 40px 40px;
        height: 40px;
        width: 40px;
    }
}

@media only screen and (min-width: 1440px) {
    .videoMenuFlashlight {
        background-size: 50px 50px;
        height: 50px;
        width: 50px;
    }
}

.videoMenuFlashlightActive {
    filter: invert(39%) sepia(55%) saturate(6400%) hue-rotate(97deg) brightness(117%) contrast(102%);
}


.videoMenuTitle {
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);

    text-align: center;
}

.videoStream {
    width: 100%;
    height: 100%;
    position: relative;
}

.focus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: calc(80vw * 0.35);

    border: 2px solid #3ACA79;
}

.inputWithScanner {
    padding-right: 60px;
}

.searchClear {
    background: url('./img/any/close-popup.svg') no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-top: 12px;
    display: block;
    position: absolute;
    left: 10px;
}

@media only screen and (min-width: 768px) {
    .searchClear {
        margin-top: 14px;
        left: 10px;
    }
}

@media only screen and (min-width: 1440px) {
    .searchClear {
        margin-top: 15px;
        left: 10px;
    }
}


.searchClear:hover {
    filter: brightness(0%) contrast(100%);
}