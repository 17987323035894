.resetContainer {
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 30px;

}

@media only screen and (min-width: 768px) {
    .resetContainer {
        margin-top: 40px;
    }
}

@media only screen and (min-width: 1440px) {
    .resetContainer {
        margin-top: 60px;
    }
}

.resetHeader {
    margin: 22px 0;
    text-align: center;
    font-size: var(--font-size-24);
    line-height: var(--line-height-24);
    font-weight: var(--font-weight-24-bold);
}

@media only screen and (min-width: 768px) {
    .resetHeader {
        margin: 25px 0;
    }
}

@media only screen and (min-width: 1440px) {
    .resetHeader {
        margin: 30px 0;
    }
}

.resetSubtitle {
    margin-bottom: 22px;
    text-align: center;
    white-space: pre-line;
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    font-weight: var(--font-weight-10);
}

@media only screen and (min-width: 768px) {
    .resetSubtitle {
        margin-bottom: 25px;
    }
}

@media only screen and (min-width: 1440px) {
    .resetSubtitle {
        margin-bottom: 30px;
    }
}

.inputWrap {
    position: relative;
}

.mb {
    margin-bottom: 17px;
}

@media only screen and (min-width: 768px) {
    .mb {
        margin-bottom: 19px;
    }
}

@media only screen and (min-width: 1440px) {
    .mb {
        margin-bottom: 30px;
    }
}

.input__label {
    position: absolute;
    left: 16px;
    top: -6px;
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    font-weight: var(--font-weight-10-bold);
    background: white;
}

@media only screen and (min-width: 768px) {
    .input__label {
        top: -9px;
    }
}

@media only screen and (min-width: 1440px) {
    .input__label {
        top: -9px;
    }
}


.input__field {
    box-sizing: border-box;
    width: 260px;
    height: 40px;
    border: 1px solid #221f1f80;
    border-radius: 4px;
    display: block;
}


@media only screen and (min-width: 768px) {
    .input__field {
        width: 320px;
        height: 40px;

    }
}

@media only screen and (min-width: 1440px) {
    .input__field {
        width: 400px;
        height: 45px;
    }
}

.btnWrapper {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    width: 100%;
}