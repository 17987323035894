.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000007a;
    top: 0;
    right: 0;
    z-index: 5;
}

@media only screen and (min-width: 768px) {
    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: space-between;
        justify-content: space-around;
        margin-top: 30vw;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: var(--font-weight-14);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
}

@media only screen and (min-width: 768px) {
    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: space-between;
        justify-content: space-around;
        margin-top: 30vw;
    }
}

@media only screen and (min-width: 1024px) {
    .menu {
        flex-direction: row;
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 0;
    }
}

.languageAndAuthWrapper {
    z-index: 7;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) {
    .languageAndAuthWrapper {
        z-index: 7;
        width: 330px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.wrapper {
    position: fixed;
    width: 220px;
    height: 100%;
    background: white;
    box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
    top: 0;
    right: 0;
    z-index: 6;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) {
    .wrapper {
        width: 384px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: initial;
    }
}

@media only screen and (min-width: 1024px) {
    .wrapper {
        display: inline-block;
        position: initial;
        top: initial;
        right: initial;
        z-index: initial;
        background: none;
        box-shadow: none;
        backdrop-filter: none;
        width: auto;
    }
}

.contain {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) {
    .contain {
        justify-content: flex-start;
    }
}

.item {
    transition: text-shadow 0.15s ease-in-out;
    text-decoration: none;
}

.item:hover {
    text-shadow: 0.25px 0.25px 0 rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 1024px) {
    .item {
        font-weight: var(--font-weight-14);
        font-size: var(--font-size-14);
        line-height: var(--line-height-14);
        color: #221f1f;
        cursor: pointer;
    }
}

.dot {
    background: #3aca79;
    min-width: 4px;
    margin: 20px 0;
    height: 4px;
    border-radius: 50%;
}

@media only screen and (min-width: 768px) {
    .dot {
        background: #3aca79;
        min-width: 4px;
        margin: 30px 1.39vw;
        height: 4px;
        border-radius: 50%;
    }
}

@media only screen and (min-width: 1024px) {
    .dot {
        background: #3aca79;
        min-width: 4px;
        margin: 0 20px;
        height: 4px;
        border-radius: 50%;
    }
}

.burger {
    cursor: pointer;
    border: initial
}

@media only screen and (min-width: 768px) {
    .burger {}
}

@media only screen and (min-width: 1024px) {
    .burger {}
}