.footer {
    margin-top: 30px;
}

@media only screen and (min-width: 768px) {
    .footer {
        margin-top: 40px;
        width: 100%;
    }
}

@media only screen and (min-width: 1440px) {
    .footer {
        margin-top: 60px;
        padding: 0px;
    }
}

.devider {
    margin: 0 auto;
    border: none;
    height: 1px;
    background-color: #3aca79;
}

.pageFooter {
    padding: 0px 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
}

@media only screen and (min-width: 768px) {
    .pageFooter {
        justify-content: space-between;
        flex-direction: row;
        padding: 20px 30px;
    }
}

@media only screen and (min-width: 768px) {
    .pageFooter {
        justify-content: space-between;
        flex-direction: row;
        padding: 20px 30px;
    }
}

.devider {
    margin: 0px 20px;
}

@media only screen and (min-width: 768px) {
    .devider {
        margin: 0px 30px;
    }
}

@media only screen and (min-width: 768px) {
    .devider {
        margin: 0px 30px;
    }
}

@media only screen and (min-width: 1440px) {
    .pageFooter {
        padding: 30px 0px;
        width: 1140px;
        justify-content: space-between;
        flex-direction: row;
    }

    .devider {
        width: 1140px;
        margin: 0 auto;
    }
}

.appFooter {
    width: 100%;
    background: #f8f8f8;
    /* padding: 10px 10.415vw; */
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media only screen and (min-width: 768px) {
    .appFooter {
        flex-direction: row;
    }
}

@media only screen and (min-width: 1440px) {
    .appFooter {
        width: 100%;
        /* margin: 0 auto; */
    }
}

.appFooterContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media only screen and (min-width: 768px) {
    .appFooterContainer {
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 30px;
        width: 100%;
    }
}

@media only screen and (min-width: 1440px) {
    .appFooterContainer {
        width: 1140px;
        margin: 0 auto;
        padding: 10px 0px;
    }
}

.installWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .installWrapper {
        width: initial;
    }
}

.installIcons {
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.installText {
    font-weight: var(--font-weight-10);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    color: #221f1f;
}

.docsWrapper {
    display: inline-block;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    .docsWrapper {
        margin: initial;
    }
}

@media only screen and (min-width: 1440px) {
    .docsWrapper {
        margin-left: 59px;
    }
}

.docs {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

@media only screen and (min-width: 768px) {
    .docs {
        flex-direction: row;
    }
}

.item {
    font-weight: var(--font-weight-10);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    color: #221f1f;
    cursor: pointer;
}

.dot {
    background: #3aca79;
    min-width: 4px;
    margin: 5px 0.69vw;
    height: 4px;
    border-radius: 50%;
}

@media only screen and (min-width: 768px) {
    .dot {
        margin: 0 0.69vw;
    }
}

.copyright {
    font-style: normal;
    font-weight: 300;
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    color: #000000;
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .copyright {
        margin: initial;
    }
}

.method {
    margin: 10px 0;
}

@media only screen and (min-width: 768px) {
    .method {
        margin: initial;
        margin-left: 0px;
    }
}

@media only screen and (min-width: 1440px) {
    .method {
        margin-left: 210px;
    }
}

.upArrow {
    cursor: pointer;
    content: url(./img/any/up.svg);
    position: fixed;
    bottom: 1%;
    right: 1%;
}

@media only screen and (min-width: 768px) {
    .upArrow {
        position: fixed;
        bottom: 3%;
        right: 1%;
    }
}

@media only screen and (min-width: 1440px) {
    .upArrow {
        margin-left: 50px;
        position: fixed;
        bottom: 1%;
        right: 1%;
    }
}

.logo {
    content: url(./img/any/logo.svg);
    width: 169px;
}

@media only screen and (min-width: 768px) {
    .logo {
        width: 169px;
    }
}

@media only screen and (min-width: 1440px) {
    .logo {
        width: 100%;
    }
}

.chromeIcon {
    content: url(./img/any/chrome.svg);
    width: 35px;
    margin-right: 10px;
}

@media only screen and (min-width: 768px) {
    .chromeIcon {
    }
}

@media only screen and (min-width: 1440px) {
    .chromeIcon {
        width: 35px;
    }
}
.edgeIcon {
    content: url(./img/any/edge.svg);
    width: 35px;
}

@media only screen and (min-width: 1440px) {
    .edgeIcon {
        width: 35px;
    }
}

.methodIcon {
    content: url(./img/any/method.svg);
    width: 95px;
}

@media only screen and (min-width: 1440px) {
    .methodIcon {
        width: 114px;
    }
}
