.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    display: inline-block;
    /* margin-left: 11.6vw; */
    margin: 15px 0px;
    min-width: 33%;
}

@media only screen and (min-width: 768px) {
    .wrapper {
        /* width: 169px; */
        margin: 0;
    }
}

.item {
    font-family: 'Jost';
    font-weight: var(--font-weight-14);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #221f1f;
    cursor: pointer;
}

.dot {
    background: #3aca79;
    min-width: 4px;
    margin: 0 10px;
    height: 4px;
    border-radius: 50%;
}

@media only screen and (min-width: 768px) {
    .dot {
        margin: 0 1.39vw;
    }
}
