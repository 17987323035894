.otherError {
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    font-weight: var(--font-weight-10);
    color: #ff6c6c;
    margin-top: -16px;
    margin-bottom: 10px;
    display: none;
}

.showError {
    display: block;
}
