.section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin: 22px 0;
    text-align: center;
    font-size: var(--font-size-24);
    line-height: var(--line-height-24);
    font-weight: var(--font-weight-24-bold);
}

@media only screen and (min-width: 768px) {
    .title {
        margin: 25px 0;
    }
}

@media only screen and (min-width: 1440px) {
    .title {
        margin: 30px 0;
    }
}

.subTitle {
    display: block;
    margin: 0 auto;
    font-style: normal;
    font-weight: var(--font-weight-14);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    text-align: center;
    color: #221f1f;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

}

@media only screen and (min-width: 768px) {
    .content {
        margin-top: 25px;
    }
}

@media only screen and (min-width: 1440px) {
    .content {
        margin-top: 30px;
    }
}

.error {
    font-weight: var(--font-weight-12-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #F84147;
}

/* .odiImg {
    background: url(./img/any/odi.jpg) center center/cover no-repeat;
    width: 100%;
} */


.imgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
    flex-direction: column;
}

@media only screen and (min-width: 768px) {
    .imgContainer {
        flex-direction: row;
    }
}

.imgText {
    font-weight: var(--font-weight-24-bold);
    font-size: var(--font-size-24);
    line-height: var(--line-height-24);
    color: #221f1f;
}