.filterScreenshot {
    content: url(./img/any/screenshot.webp);
    width: 100%;
    box-shadow: 0px 0px 15px rgba(34, 31, 31, 0.1);
}

@media only screen and (min-width: 768px) {
    .filterScreenshot {
        /* content: url(./img/medium/screenshot.png); */
        max-width: 550px;
    }
}

@media only screen and (min-width: 1440px) {
    .filterScreenshot {
        /* content: url(./img/big/screenshot.png); */
        box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
    }
}

.filterContainer {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
}

@media only screen and (min-width: 768px) {
    .filterContainer {
        margin-top: 60px;
        flex-direction: row;
    }
}

@media only screen and (min-width: 1440px) {
    .filterContainer {
        margin-top: 80px;
        flex-direction: row;
    }
}

.filterScreenshotWrapper {
    margin-top: 30px;
}

@media only screen and (min-width: 768px) {
    .filterScreenshotWrapper {
        margin-top: 0px;
        width: 50%;
    }
}

@media only screen and (min-width: 1440px) {
    .filterScreenshotWrapper {
        width: 50%;
    }
}

.filterDescription {
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .filterDescription {
        width: 50%;
        margin-left: 40px;
        text-align: left;
    }
}

@media only screen and (min-width: 1440px) {
    .filterDescription {
        width: 50%;
    }
}

.filterDescriptionTitle {
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);
    color: #221f1f;
}

.filterDescriptionText {
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f;
    margin-top: 10px;
}
