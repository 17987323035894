.menuItem {
    cursor: pointer;
    font-weight: 300;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    text-align: center;
    color: #221f1f;
    padding: 10px 15px;
    list-style-type: none;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .menuItem {
        padding: 10px 15px;
    }
}

@media only screen and (min-width: 1440px) {
    .menuItem {
        padding: 10px 30px;
    }
}

.menuItem.selected {
    font-weight: 700;
    background: #f8f8f8;
    border-radius: 4px;
}

.menu {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: cornflowerblue; */
    padding: 0;
    display: none;
    margin: 0;
    background: #f8f8f8;
    width: 100%;
}
@media only screen and (min-width: 768px) {
    .menu {
        display: flex;
        background: transparent;
    }
}
.menu.active {
    display: flex;
    position: absolute;
    z-index: 2;
}

@media only screen and (min-width: 768px) {
    .showMenu {
        display: flex;
    }
}

.showMenu {
    background: #f8f8f8;
    border-radius: 4px;
    height: 40px;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #221f1f;
    font-weight: var(--font-weight-12-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    margin-top: 15px;
}

@media only screen and (min-width: 768px) {
    .showMenu {
        display: none;
    }
}

.showMenu.active {
    border-radius: 4px 4px 0 0;
}

.arrow {
    border: solid #3aca79;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    margin-bottom: 2px;
    margin-left: 5px;
    transform: rotate(45deg);
    transition: transform 0.5s;
}

.active .arrow {
    transform: rotate(-135deg);
    transition: transform 0.5s;
    margin-bottom: 0px;
}
