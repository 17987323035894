.section {
    margin-top: 40px;
    position: relative;
    display: block;
}

@media only screen and (min-width: 768px) {
    .section {
        margin-top: 80px;
    }
}

@media only screen and (min-width: 1440px) {
    .section {
        margin-top: 120px;
    }
}


.greenLine {
    width: 100vw;
    height: 129px;
    background: #3ACA79;
    position: absolute;
    transform: translate(-20px, 0px);
    z-index: -1;
    top: 0;
}

@media only screen and (min-width: 768px) {
    .greenLine {
        height: 90px;
        transform: translate(-30px, 0px);
    }
}


@media only screen and (min-width: 1440px) {
    .greenLine {
        height: 122px;
        width: 100%;
    }
}


.header {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 50px 0px 50px;
}

@media only screen and (min-width: 768px) {
    .header {
        color: #FFF;
        text-align: left;
        max-width: 50%;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 16px 0px 16px 26px;
    }
}

@media only screen and (min-width: 1440px) {
    .header {
        font-size: 36px;
        padding: 18px 0px;
    }
}

.card {
    background: #FFFFFF;
    box-shadow: 0px 0px 25px 10px rgba(34, 31, 31, 0.10);
    border-radius: 10px;
    padding: 15px 17px;
    margin-top: 10px;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;

    display: flex;
    flex-direction: column;

    padding: 12px 15px 17px 15px;
}

@media only screen and (min-width: 768px) {
    .card {
        margin-top: 0px;
        top: -26px;
        transform: translate(-40%, 0%);
        left: 75%;
        right: unset;
        padding: 24px;

    }
}

@media only screen and (min-width: 1440px) {
    .card {
        transform: translate(-50%, 0%);
        left: 75%;
        right: unset;
        padding: 24px;
    }
}


.vidgetIcon {
    align-self: end;
    background: url(./img/small/vidget-icon.svg) center center/cover no-repeat;
    min-width: 72.931px;
    min-height: 37.346px;
}

@media only screen and (min-width: 768px) {
    .vidgetIcon {
        background: url(./img/small/vidget-icon-tablet.svg) center center/cover no-repeat;
        min-width: 120.103px;
        min-height: 61.502px;
    }
}

@media only screen and (min-width: 1440px) {
    .vidgetIcon {
        background: url(./img/small/vidget-icon-big.png) center center/cover no-repeat;
        min-width: 171px;
        min-height: 87.565px;
    }
}

.vidgetImg {
    background: url(./img/small/vidget.png) center center/cover no-repeat;
    width: 153.113px;
    height: 159.51px;
}

@media only screen and (min-width: 768px) {
    .vidgetImg {
        background: url(./img/medium/vidget.png) center center/cover no-repeat;
        min-width: 250px;
        min-height: 278px;
        margin-top: 6px;
    }
}

@media only screen and (min-width: 1440px) {
    .vidgetImg {
        background: url(./img/medium/vidget.png) center center/cover no-repeat;
        min-width: 359px;
        min-height: 399px;
        margin-top: 8px;
    }
}

.list {
    display: flex;
    flex-direction: column;
    margin-top: 265px;
    gap: 16px;
}

@media only screen and (min-width: 768px) {
    .list {
        margin-top: 35px;
        max-width: 50%;
        gap: 18px;
        margin-left: 26px;
    }
}

@media only screen and (min-width: 1440px) {
    .list {
        margin-top: 80px;
        gap: 24px;
        margin-left: 0px;
    }
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

@media only screen and (min-width: 768px) {
    .item {
       gap: 25px;
    }
}

@media only screen and (min-width: 1440px) {
    .item {
        gap: 35px;
    }
}

.check {
    background: url(./img/small/check.svg) center center/cover no-repeat;
    width: 27px;
    height: 20px;
    flex-shrink: 0;
}

@media only screen and (min-width: 768px) {
    .check {
        width: 30px;
        height: 23px;
    }
}

@media only screen and (min-width: 1440px) {
    .check {
        width: 32px;
        height: 24px;
    }
}


.text {
    color: #221F1F;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media only screen and (min-width: 768px) {
    .text {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1440px) {
    .text {
        font-size: 16px;
    }
}
