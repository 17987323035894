.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.title {
  font-weight: var(--font-weight-16-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-16);
  margin: 40px 0 15px 0;
  text-align: center;
}

.wrapper {
  width: 80%;
}

.section {
  padding: 20px;
}

.tableWrapper {
  margin-top: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(34, 31, 31, .1);
  padding: 20px;
}

.tableTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
  cursor: pointer;
  position: relative;
}

.table th::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.table th.sort-ascending::after {
  border-top: none;
  border-bottom: 4px solid black;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

.table tbody tr:first-child {
  font-weight: bold;
  background-color: #e9e9e9;
}

.table tbody tr:nth-child(2) {
  font-weight: bold;
  background-color: #e9e9e9;
}


.resetButton {
  padding: 8px 16px;
  margin: 0 auto;
  display: block;
  margin-top: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.resetButton:hover {
  background-color: #0056b3;
}
