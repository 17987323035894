.wrapper {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .wrapper {
        display: flex;
    }
}

@media only screen and (min-width: 1440px) {
    .wrapper {
        display: flex;
    }
}

.separator {
    width: 2px;
    min-height: 100%;
    margin: 0 10px;
    background-color: #3aca79;
}

@media only screen and (min-width: 768px) {
    .separator {
        background-color: #3aca79;
    }
}

@media only screen and (min-width: 1440px) {
    .separator {
        background-color: #3aca79;
    }
}

.login,
.register {
    font-weight: var(--font-weight-12-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f;
}

.email {
    font-weight: 400;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f;
    margin-left: 10px;   
}

@media only screen and (min-width: 768px) {
    .login,
    .register {
        color: #221f1f;
    }
}

@media only screen and (min-width: 1440px) {
    .login,
    .register,
    .email {
        color: #221f1f;
        cursor: pointer;
    }
}

.icon {
    /* content: url(./img/any/notifications-count.svg); */
    width: 100%;
    height: 20px;
}

@media only screen and (min-width: 768px) {
    .icon {
        width: 100%;
        height: 25px;
    }
}


@media only screen and (min-width: 1440px) {
    .whiteText > .login,
    .whiteText > .register,
    .whiteText > .email {
        color: #ffffff;
    }

    .whiteText > .separator {
        background-color: #ffffff;
    }

    .whiteIcon {
        content: url(./img/any/whiteIcon.svg);
    }
}
.profileWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}