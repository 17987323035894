.imageWrapper {
    position: relative;
    display: block;
    margin-bottom: 8px;
    overflow: hidden;
    min-width: 150px;
    min-height: 150px;
    overflow: hidden;
}

@media only screen and (min-width: 768px) {
    .imageWrapper {
        min-width: 250px;
        min-height: 250px;
    }
}

@media only screen and (min-width: 1440px) {
    .imageWrapper {
        min-width: 400px;
        min-height: 400px;
    }
}

.removeMargin {
    margin-top: 40px !important;
}


@media only screen and (min-width: 768px) {
    .removeMargin {
        /* flex-direction: row; */
        margin-top: 60px !important;;
        /* column-gap: 20px; */
    }
}

@media only screen and (min-width: 1440px) {
    .removeMargin {
        margin-top: 80px !important;;
        /* column-gap: 120px; */
    }
}

.img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    z-index: -1;
}

.goodWrapper {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    column-gap: 10px;
    min-width: 100%;
}

@media only screen and (min-width: 768px) {
    .goodWrapper {
        flex-direction: row;
        margin-top: 80px;
        column-gap: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodWrapper {
        margin-top: 120px;
        column-gap: 30px;
    }
}

.name {
    font-weight: 700;
    font-size: var(--font-size-18);
    line-height: var(--line-height-18);
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
    text-decoration: underline;
    text-decoration-color: #3aca79;
    text-underline-position: under;
}

@media only screen and (min-width: 768px) {
    .name {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .name {
        margin-bottom: 30px;
    }
}

.discount {
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
}

.discount>span {
    font-weight: 700;
}


.price {
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
}

.price>span {
    color: #3aca79;
    font-weight: var(--font-weight-14-bold);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
}


.price>.blackColor {
    color: #221f1f;
}

.greenColor {
    color: #3aca79;
}

.descriptionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-width: 100%;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.goodButton {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 16px;
    transition: all 0.15s ease;
    border-radius: 30px;
    border: 1px solid #3ACA79;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: right;
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .goodButton {
        padding: 10px 24px;
        font-size: 14px;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodButton {
        font-size: 16px;
        padding: 14px 44px;
        margin-top: 30px;
    }
}
