.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.manageSection {
    margin-top: 15px;
}

@media only screen and (min-width: 768px) {
    .manageSection {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .manageSection {
        margin-top: 30px;
    }
}
.manageValue {
    font-size: var(--font-size-12);
    font-weight: 400;
    line-height: var(--line-height-12);
    margin-left: 10px;
}

.title {
    font-weight: var(--font-weight-16-bold);
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    text-align: center;
    /* margin-top: 15px; */
}

.subscriptionStatsWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding: 0px 15px;
}

@media only screen and (min-width: 768px) {
    .subscriptionStatsWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2vh;
        column-gap: 2vw;
    }
}

@media only screen and (min-width: 1440px) {
    .subscriptionStatsWrapper {
        margin-top: 20px;
    }
}

.stats {
    display: flex;
}

@media only screen and (min-width: 768px) {
    .stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.statsTitle {
    font-weight: 400;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f;
}

.statsValue {
    font-weight: var(--font-weight-12-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #3aca79;
    margin-left: 10px;
}

.btnWrapper {
    margin-top: 15px;
}

@media only screen and (min-width: 1440px) {
    .btnWrapper {
        margin-top: 20px;
    }
}

.redText > .statsValue {
    color: #f84147;
}

.unsubscribeBtnWrapper > button {
    background: linear-gradient(94.52deg, #e36810, #ffc890);
    padding: 9px 14px;
}

.unsubscribeBtnWrapper > button:hover {
    background: linear-gradient(94.52deg, #e36810, #ffc890);
    padding: 9px 14px;
    box-shadow: 0 5px 10px hsl(0deg 100% 78% / 50%);
}

.createdDesc {
    margin-top: 15px;
    white-space: pre-wrap;
    /* font-weight: var(--font-weight-12); */
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .createdDesc {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .createdDesc {
        margin-top: 30px;
    }
}