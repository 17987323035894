.section {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) {
  .section {
    margin-top: 91px;
  }
}
@media only screen and (min-width: 1440px) {
  .section {
    margin-top: 120px;
  }
}

.header {
  display: block;
  margin: 0 auto;
  font-style: normal;
  font-weight: var(--font-weight-22);
  font-size: var(--font-size-22);
  line-height: var(--line-height-22);
  text-align: center;
  color: #221f1f;
}

.subHeader {
  display: block;
  margin: 0 auto;
  font-style: normal;
  font-weight: var(--font-weight-12);
  font-size: var(--font-size-12);
  line-height: var(--line-height-12);
  text-align: center;
  color: #221f1f;
  margin-top: 10px;
}

.tag {
  display: inline-block;
  margin-left: 10px;
  background: url(./img/small/for-free-tag.svg) center center/cover no-repeat;
  width: 73px;
  height: 25px;
}

@media only screen and (min-width: 768px) {
  .tag {
    background: url(./img/medium/for-free-tag.svg) center center/cover no-repeat;
    min-width: 58px;
    height: 20px;
    margin-left: 10px;
  }
}

@media only screen and (min-width: 1440px) {
  .tag {
    background: url(./img/big/for-free-tag.svg) center center/cover no-repeat;
    width: 90px;
    height: 31px;
    margin-left: 20px;
  }
}

.textFreeAndTag {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

@media only screen and (min-width: 1440px) {
  .textFreeAndTag {
    margin-top: 20px;
  }
}

.textFree {
  font-style: normal;
  font-weight: var(--font-weight-12);
  font-size: var(--font-size-12);
  line-height: var(--line-height-12);
  color: #221f1f;
}

.installBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.installBtnWrapper a {
  color: #ffffff;
  font-style: normal;
  font-weight: var(--font-weight-10-bold);
  font-size: var(--font-size-10);
  line-height: var(--line-height-10);
}
@media only screen and (min-width: 768px) {
  .installBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 1440px) {
  .installBtnWrapper {
    display: inline-block;
  }
}

.description {
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  .description {
    width: 43%;
  }
}

@media only screen and (min-width: 1440px) {
  .description {
    width: 50%;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  flex-direction: column-reverse;
}

@media only screen and (min-width: 768px) {
  .content {
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1440px) {
  .content {
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
    flex-direction: row;
  }
}

.screenshotWrapper {
  display: block;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .screenshotWrapper {
    display: inline-block;
    margin: initial;
    margin-left: 20px;
    width: 54%;
  }
}
@media only screen and (min-width: 1440px) {
  .screenshotWrapper {
    display: inline-block;
    margin: initial;
    padding-top: 15px;
    margin-left: 40px;
    width: 50%;
  }
}

.screenshot {
  box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
  content: url(./img/any/screenshot.webp);
  width: 100%;
}
/* 
@media only screen and (min-width: 768px) {
    .screenshot {
        content: url(./img/medium/screenshot.png);
        max-width: 550px;
        margin: 0 auto;
    }
}
@media only screen and (min-width: 1440px) {
    .screenshot {
        content: url(./img/big/screenshot.png);
    }
} */
