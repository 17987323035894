@import '~sanitize.css';
@import '~sanitize.css/forms.css';
@import '~sanitize.css/typography.css';

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400;700&display=swap');

/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 100;
    src: url(/public/fonts/Jost-ExtraLight.woff2) format('woff2');
    unicode-range: U+0407, U+0404, U+0454, U+0456, U+0406, U+0457;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    src: url(/public/fonts/Jost-Light.woff2) format('woff2');
    unicode-range: U+0407, U+0404, U+0454, U+0456, U+0406, U+0457;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    src: url(/public/fonts/Jost-Regular.woff2) format('woff2');
    unicode-range: U+0407, U+0404, U+0454, U+0456, U+0406, U+0457;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/public/fonts/Jost-Bold.woff2) format('woff2');
    unicode-range: U+0407, U+0404, U+0454, U+0456, U+0406, U+0457;
}

.app {
    font-family: 'Jost', sans-serif;
    /* max-width: 100vw; */
    width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

a {
    text-decoration: none;
    /* Отменяем подчеркивание у ссылки */
    color: initial;
}

.container {
    width: 100%;
    /* max-width: 360px; */
    padding: 0 20px;
    position: relative;
    margin: 0 auto;
    max-width: 1140px;
    flex: 1;
}

@media only screen and (min-width: 768px) {
    .container {
        width: 100%;
        /* max-width: 768px; */
        padding: 0 30px;
    }
}

@media only screen and (min-width: 1140px) {
    .container {
        padding: 0px;
    }
}

@media only screen and (min-width: 1440px) {
    .container {
        width: 1140px;
        max-width: 1140px;
        padding: 0px;
    }
}

/* body { */
/* max-height: 100vh;
  overflow-y: hidden; */
/* height: 1578px; */
/* } */

:root {
    --font-size-24: 24px;
    --line-height-24: 35px;
    --font-weight-24: 700;
    --font-weight-24-bold: 700;

    --font-size-22: 22px;
    --line-height-22: 32px;
    --font-weight-22: 700;
    --font-weight-22-bold: 700;

    --font-size-20: 20px;
    /* --line-height-20: 23px; */
    --font-weight-20: 300;
    --font-weight-20-bold: 700;
    --font-weight-20-medium: 500;

    --font-size-18: 18px;
    --line-height-18: 23px;
    --font-weight-18: 300;
    --font-weight-18-bold: 700;
    --font-weight-18-medium: 500;


    --font-size-16: 16px;
    --line-height-16: 23px;
    --font-weight-16: 300;
    --font-weight-16-bold: 700;

    --font-size-14: 14px;
    --line-height-14: 20px;
    --font-weight-14: 400;
    --font-weight-14-bold: 700;

    --font-size-12: 12px;
    --line-height-12: 17px;
    --font-weight-12: 300;
    --font-weight-12-bold: 700;

    --font-size-10: 10px;
    --line-height-10: 14px;
    --font-weight-10: 400;
    --font-weight-10-bold: 700;
}

@media only screen and (min-width: 768px) {
    :root {
        --font-size-24: 30px;
        --line-height-24: 43px;
        --font-weight-24: 700;
        --font-weight-24-bold: 700;

        --font-size-22: 28px;
        --line-height-22: 40px;
        --font-weight-22: 700;

        --font-size-20: 32px;
        /* --line-height-20: 23px; */
        --font-weight-20: 300;
        --font-weight-20-bold: 700;
        --font-weight-20-medium: 500;

        --font-size-18: 20px;
        --line-height-18: 26px;
        --font-weight-18: 300;
        --font-weight-18-bold: 700;

        --font-size-16: 18px;
        --line-height-16: 26px;
        --font-weight-16: 700;


        --font-size-14: 16px;
        --line-height-14: 23px;
        --font-weight-14: 400;
        --font-weight-14-bold: 700;

        --font-size-12: 14px;
        --line-height-12: 20px;
        --font-weight-12: 300;
        --font-weight-12-bold: 700;

        --font-size-10: 12px;
        --line-height-10: 17px;
        --font-weight-10: 400;
        --font-weight-10-bold: 700;
    }
}

@media only screen and (min-width: 1440px) {
    :root {
        --font-size-24: 42px;
        --line-height-24: 61px;
        --font-weight-24: 700;
        --font-weight-24-bold: 700;

        --font-size-22: 36px;
        --line-height-22: 52px;
        --font-weight-22: 700;

        --font-size-20: 40px;
        /* --line-height-20: 23px; */
        --font-weight-20: 300;
        --font-weight-20-bold: 700;
        --font-weight-20-medium: 500;

        --font-size-18: 22px;
        --line-height-18: 29px;
        --font-weight-18: 300;
        --font-weight-18-bold: 700;

        --font-size-16: 20px;
        --line-height-16: 29px;
        --font-weight-16: 700;

        --font-size-14: 18px;
        --line-height-14: 26px;
        --font-weight-14: 400;
        --font-weight-14-bold: 700;

        --font-size-12: 16px;
        --line-height-12: 23px;
        --font-weight-12: 300;
        --font-weight-12-bold: 700;

        --font-size-10: 14px;
        --line-height-10: 20px;
        --font-weight-10: 400;
        --font-weight-10-bold: 700;
    }
}

.appLoader {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    height: 100%;
    flex-direction: column;
}

.logoName {
    text-align: center;
    font-size: var(--font-size-24);
    line-height: var(--line-height-24);
}