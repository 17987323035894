.section {
  margin-top: 16px;
}

@media only screen and (min-width: 768px) {
  .section {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 1440px) {
  .section {
    margin-top: 44px;
  }
}

.header {
  font-weight: var(--font-weight-22);
  font-size: var(--font-size-22);
  line-height: var(--line-height-22);
  text-align: center;
  color: #221f1f;
}

.questionsContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (min-width: 768px) {
  .questionsContainer {
    gap: 20px;
    margin-top: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1440px) {
  .questionsContainer {
    margin-top: 60px;
  }
}

.questionHeaderWrapper {
  display: flex;
  align-items: center;
}

.questionHeader {
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: var(--line-height-16);
  color: #000107;
  margin-left: 20px;
}

.answer {
  font-weight: var(--font-weight-14);
  font-size: var(--font-size-14);
  line-height: var(--line-height-14);
  color: #000107;
  margin-left: 40px;
  padding-right: 3vw;
  margin-top: 10px;
  white-space: pre-line;
}

@media only screen and (min-width: 1024px) {
  .answer {
    margin-left: 50px;
  }
}

.question {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .question {
    margin-top: initial;
    width: 47%;
  }
}

.questionIcon {
  padding-top: 5px;
  content: url(./img//question-icon.svg);
  width: 20px;
}

@media only screen and (min-width: 1024px) {
  .questionIcon {
    width: 30px;
  }
}
.answer a {
  color: #3aca79;
}