/* .overlay {
  display: none;
}

@media only screen and (min-width: 768px) {
  .overlay {
    display: initial;
  }
} */

.overlay.active {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000007a;
    top: 0;
    right: 0;
    z-index: 3;
}

.menuItem,
.menuItem_logout {
    cursor: pointer;
    font-weight: 300;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    text-align: center;
    color: #221f1f;
    list-style-type: none;
    height: 43px;
    width: 100%;
    border-bottom: 1px solid #3aca794a;
}
.menuItem_logout {
    padding: 10px 0;
}
.menuItem a {
    display: block;
    line-height: 43px;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .menuItem {
        border-bottom: none;
    }
}

@media only screen and (min-width: 1440px) {
    .menuItem {
        border-bottom: none;
    }
}

.menuItem:hover {
    background: #f8f8f8;
    border-radius: 4px;
}

.menuItem.selected {
    font-weight: 700;
}

.menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: none;
    margin: 0;
    background: #f8f8f8;
    width: 100%;
    border-radius: 0 0 4px 4px;
}
@media only screen and (min-width: 768px) {
    .menu {
        display: flex;
        background: transparent;
        border-radius: initial;
    }
}
.menu.active {
    display: flex;
    position: absolute;
    z-index: 4;
}

@media only screen and (min-width: 768px) {
    .showMenu {
        display: flex;
    }
}

.showMenu {
    position: relative;
    background: #d6f4e3;
    border-radius: 4px;
    height: 40px;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #221f1f;
    font-weight: var(--font-weight-12-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    margin-top: 15px;
}

@media only screen and (min-width: 768px) {
    .showMenu {
        display: none;
    }
}

.showMenu.active {
    border-radius: 4px 4px 0 0;
    z-index: 4;
}

.arrow {
    border: solid #3aca79;
    border-width: 0px 3px 3px 0;
    display: inline-block;
    padding: 4px;
    margin-bottom: 2px;
    margin-left: 5px;
    transform: rotate(45deg);
    transition: transform 0.5s;
}

.active .arrow {
    transform: rotate(-135deg);
    transition: transform 0.5s;
    margin-bottom: 0px;
}
