.section {
    width: 100%;
}

.goodsWrapper {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    gap: 10px;
    margin-top: 10px;
}

@media only screen and (min-width: 660px) {
    .goodsWrapper {
        margin-top: 0px;
    }
}

@media only screen and (min-width: 905px) {
    .goodsWrapper {
        grid-template-columns: repeat(2, calc(50% - 5px));
    }
}

@media only screen and (min-width: 1140px) {
    .goodsWrapper {
        grid-template-columns: repeat(3, calc(33% - 10px));
        gap: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodsWrapper {
        grid-template-columns: repeat(3, calc(33% - 10px));
        gap: 20px;
    }
}


.containerCard {
    padding: 15px;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 5%);
    border-radius: 8px;
}



[data-tooltip] {
    position: relative; /* Относительное позиционирование */
}
[data-tooltip]::after {
    content: attr(data-tooltip); /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    width: 300px; /* Ширина подсказки */
    left: 0;
    top: 0; /* Положение подсказки */
    background: #ffffff; /* Синий цвет фона */
    color: rgb(0, 0, 0); /* Цвет текста */
    padding: 5px; /* Поля вокруг текста */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
    pointer-events: none; /* Подсказка */
    opacity: 0; /* Подсказка невидима */
    transition: 1s; /* Время появления подсказки */
}

/* [data-tooltip]:hover::after {
    opacity: 1; 
    top: 100px; 
    z-index: 999999;
} */

.title {
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    margin-top: 5px;
    height: 46px;
    overflow: hidden;
    /* max-width: 230px; */
}

@media only screen and (min-width: 768px) {
    .title {
        margin-top: 10px;
        /* max-width: 230px; */
    }
}

@media only screen and (min-width: 905px) {
    .title {
        /* max-width: 230px; */
    }
}

@media only screen and (min-width: 1440px) {
    .title {
        margin-top: 15px;
        /* max-width: 230px; */
    }
}

.price {
    color: #3aca79;
    font-weight: var(--font-weight-14-bold);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    margin-top: 5px;
    padding: 0px 5px;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .price {
        margin-top: 10px;
    }
}

@media only screen and (min-width: 1440px) {
    .price {
        margin-top: 15px;
    }
}

.lowPrice {
    white-space: pre-wrap;
}

.lowPrice > span {
    top: 5px;
    left: 5px;
    z-index: 1;

    padding: 3px 8px;

    font-weight: var(--font-weight-10-bold);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);

    border-radius: 14px;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    background-color: #f84147;
}

.lowPrice:hover::after {
    content: attr(content);
    white-space: pre-wrap;
    width: 100%;
    background-color: white;
    padding: 10px;
    position: absolute;
    top: 7%;
    left: 0;
    z-index: 2;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgb(112 112 112);
    font-family: 'Jost';
    font-weight: 500;
}

.containerImg {
    position: relative;
    display: block;
    margin-bottom: 8px;
    overflow: hidden;
    min-width: 224px;
    min-height: 295px;
}

.img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    max-height: 100%;
    z-index: -1;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noGoods {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: var(--font-weight-14-bold);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
}