.section {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.title {
    margin: 22px 0;
    text-align: center;
    font-size: var(--font-size-24);
    line-height: var(--line-height-24);
    font-weight: var(--font-weight-24-bold);
    color: #221f1f;
}
.subtitle {
    font-style: normal;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    text-align: center;
    color: #221f1f;
}
.wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .wrapper {
        width: 70%;
    }
}

@media only screen and (min-width: 1440px) {
    .wrapper {
        width: 70%;
    }
}

.menuItem {
    cursor: pointer;
    font-weight: 300;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    text-align: center;
    color: #221f1f;
    padding: 10px 15px;
    list-style-type: none;
    width: 100%;
    border-bottom: 1px solid #3cce7c3b;
}

.menuItem.selected {
    font-weight: 700;
    background: #f8f8f8;
    border-radius: 4px;
}

.menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: none;
    margin: 0;
    background: #f8f8f8;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .menu {
        width:100%;
    }
}

@media only screen and (min-width: 1440px) {
    .menu {
        width: 100%;
    }
}

.menu.active {
    display: flex;
    position: absolute;
    z-index: 4;
}

.showMenu {
    position: relative;
    background: #d6f4e3;
    border-radius: 4px;
    height: 40px;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #221f1f;
    font-weight: var(--font-weight-12-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    margin-top: 15px;
    cursor: pointer;
}

.showMenu.active {
    border-radius: 4px 4px 0 0;
    z-index: 4;
}

.arrow {
    border: solid #3aca79;
    border-width: 0px 3px 3px 0;
    display: inline-block;
    padding: 4px;
    margin-bottom: 2px;
    margin-left: 5px;
    transform: rotate(45deg);
    transition: transform 0.5s;
}

.active .arrow {
    transform: rotate(-135deg);
    transition: transform 0.5s;
    margin-bottom: 0px;
}

.textarea {
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
    height: 20vh;
    color: #221f1f;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    resize: none;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .textarea {
        width: 70%;
    }
}

@media only screen and (min-width: 1440px) {
    .textarea {
        width: 70%;
    }
}
.btn {
    margin: 0 auto;
}

.overlay.active {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0000007a;
    top: 0;
    right: 0;
    z-index: 3;
}