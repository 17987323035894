.goodCardContainer {
    margin-top: 80px;
    display: flex;
    align-items: center;
    position: relative;
}

@media only screen and (min-width: 1440px) {
    .goodCardContainer {
        margin-top: 80px;
        display: flex;
        align-items: center;
    }
}

.rightNavigatorIconWrapper2 {
    position: absolute;
    top: calc(50%);
    right: -60px;
}

.featureDescription {
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .featureDescription {
        min-width: 270px;
        text-align: initial;
    }
}

@media only screen and (min-width: 1440px) {
    .featureDescription {
        max-width: 550px;
    }
}

.featureDescriptionTitle {
    font-style: normal;
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);
    color: #221f1f;
}

.featureDescriptionText {
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    margin-top: 10px;
}

@media only screen and (min-width: 1440px) {
    .featureDescriptionText {
        margin-top: 10px;
    }
}

.goodCardWrapper {
    margin-top: 30px;
}

@media only screen and (min-width: 768px) {
    .goodCardWrapper {
        margin-top: 0px;
        margin-left: 10px;
        width: 63%;
    }
}

@media only screen and (min-width: 1440px) {
    .goodCardWrapper {
        margin-left: 20px;
        width: initial;
    }
}

.goodCardImage {
    content: url(./img/any/card.png);
    width: 75%;
    margin: 0 auto;
    box-shadow: 0px 0px 15px rgb(34 31 31 / 10%);
}

@media only screen and (min-width: 768px) {
    .goodCardImage {
        content: url(./img/any/card.png);
        width: 100%;
        max-width: 255px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodCardImage {
        content: url(./img/any/card.png);
        box-shadow: 0px 0px 20px rgb(34 31 31 / 10%);
        width: 255px;
        height: 520px;
    }
}

.goodCardDescription {
    margin: 0 auto;
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .goodCardDescription {
        margin-top: 0px;
        margin-left: 10px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodCardDescription {
        margin-left: 25px;
    }
}

.goodCardDescriptionText {
    margin-left: 10px;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
}

@media only screen and (min-width: 1440px) {
    .goodCardDescriptionText {
        margin-left: 10px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodCardDescriptionText {
        margin-left: 10px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 23px;
        color: #221f1f;
    }
}

.goodCardContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    .goodCardContainer {
        margin-top: 60px;
        flex-direction: row;
    }
}

@media only screen and (min-width: 1440px) {
    .goodCardContainer {
        margin-top: 80px;
    }
}

.arrowIcon {
    background: url(./img/any/arrow.svg) center center/cover no-repeat;
    min-width: 35px;
    min-height: 35px;
}

.goodCardDescriptionItem {
    margin: 20px 0;
    display: flex;
    align-items: center;
}
