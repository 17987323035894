.section {
  padding-right: 10px;
  padding-left: 10px;
}
@media only screen and (min-width: 1440px) {
  .section {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.settingsTitle {
  font-weight: var(--font-weight-16-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-16);
  text-align: center;
  margin-bottom: 15px;
}

.settingsFlex {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-evenly;
}

@media only screen and (min-width: 1440px) {
  .settingsFlex {
    flex-direction: row;
  }
}

