.btn {
    border: none;
    border-radius: 4px;
    background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
    font-style: normal;
    color: #ffffff;
    padding: 12px 25px;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    font-weight: var(--font-weight-10-bold);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
}

/* 
@media only screen and (min-width: 768px) {
    .btn {
        padding: 12px 25px;
    }
}

@media only screen and (min-width: 1440px) {
    .btn {
        padding: 12px 25px;
    }
} */

.btn:hover {
    background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
    box-shadow: 0px 5px 10px rgba(81, 245, 153, 0.5);
}

.btn:active {
    background: #34a867;
    box-shadow: none;
}

.btn:disabled {
    background: #e5e5e5;
    color: #b3b3b3;
    cursor: not-allowed;
}

.btn:disabled:hover {
    box-shadow: none;
}

