.menuWrapper {
    position: relative;
}
@media only screen and (min-width: 768px) {
    .menuWrapper {
        max-width: max-content;
        min-width: max-content;
        display: inline-block;
    }
}

.pageContent {
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 15px 0px;
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .pageContent {
        margin: 0px;
        margin-left: 18px;
        padding: 20px 0px;
        flex: 1 0 auto;
        width: 0%;
    }
}

@media only screen and (min-width: 1440px) {
    .pageContent {
        margin-left: 40px;
        padding: 30px 0px;
    }
}

@media only screen and (min-width: 768px) {
    .cabinetContainer {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .cabinetContainer {
        margin-top: 40px;
    }
}