.section {
  position: relative;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .section {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1440px) {
  .section {
    margin-top: 120px;
  }
}

.header {
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #221f1f;
}

@media only screen and (min-width: 768px) {
  .header {
    /* margin-top: 20px; */
    font-size: 40px;
    text-align: left;
    max-width: 75%;
  }
}

@media only screen and (min-width: 1440px) {
  .header {
    text-align: left;
    font-size: 64px;
    line-height: 45px;
    /* max-width: 493px; */
  }
}

.linkToYoutube {
  box-shadow: 10px 10px 20px 10px rgb(0 0 0 / 10%);
  width: min-content;
  border-radius: 10px;
  overflow: hidden;
}

.description {
  font-style: normal;
  font-weight: var(--font-weight-20);
  font-size: var(--font-size-20);
  line-height: var(--line-height-20);
  text-align: center;
  color: #221f1f;
  margin-top: 4px;
}

@media only screen and (min-width: 768px) {
  .description {
    text-align: left;
    /* max-width: 75%; */
    margin-top: 6px;
  }
}

@media only screen and (min-width: 1440px) {
  .description {
    text-align: left;
    margin-top: 24px;
  }
}

.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.buttons a {
  color: #ffffff;
  font-style: normal;
  font-weight: var(--font-weight-10-bold);
  font-size: var(--font-size-10);
  line-height: var(--line-height-10);
}

@media only screen and (min-width: 768px) {
  .buttons {
    margin-top: 0px;
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1440px) {
  .buttons {
    justify-content: flex-start;
  }
}

.discountCatalogBtnWrapper {
  margin-left: 15px;
  display: inline-block;
}

@media only screen and (min-width: 1440px) {
  .discountCatalogBtnWrapper {
    margin-left: 20px;
  }
}

.screenshots {
  /* content: url(./img/any/screenshots.webp); */
  width: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

/* @media only screen and (max-width: 379px) {
  .screenshots {
  }
} */

@media only screen and (min-width: 768px) {
  .screenshots {
    position: relative;
    margin-top: 20px;
    margin-bottom: 0;
    /* content: url(./img/medium/screenshots.png); */
    /* max-width: 791px; */
  }
}

.screenshotOne {
  content: url(./img/any/catalog-1.png);
  width: 100%;
}

.screenshotTwo {
  content: url(./img/any/catalog-2.png);
  width: 100%;
}

.screenshots img {
  box-shadow: 0px 0px 20px 10px rgb(0 0 0 / 10%);
}

@media only screen and (min-width: 1440px) {
  .screenshots {
    max-width: 65%;
    position: absolute;
    right: -109px;
    top: 17px;
  }
}

.statistics {
  display: grid;
  grid-template-columns: 1fr 8px 1fr;
  grid-template-rows: 35px;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  column-gap: 15px;
  row-gap: 15px;

  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(34, 31, 31, 0.1);
  border-radius: 8px;
  padding: 10px;
}

@media only screen and (min-width: 768px) {
  .statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;
    padding: 13px;

    column-gap: initial;
    row-gap: initial;
  }
}

@media only screen and (min-width: 1440px) {
  .statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 174px;
    padding: 10px;

    column-gap: initial;
    row-gap: initial;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.value {
  font-style: normal;
  font-weight: var(--font-weight-24);
  font-size: var(--font-size-24);
  line-height: var(--line-height-24);
  color: #221f1f;
}

@media only screen and (min-width: 768px) {
  .value {
    font-size: 30px;
    line-height: 43px;
  }
}

@media only screen and (min-width: 1440px) {
  .value {
    font-size: 42px;
    line-height: 61px;
  }
}

.text {
  font-style: normal;
  /* font-weight: var(--font-weight-12); */
  font-size: var(--font-size-12);
  line-height: var(--line-height-12);
  margin-left: 5px;
  color: #221f1f;
  max-width: 92px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .text {
    max-width: initial;
  }
}

@media only screen and (min-width: 1440px) {
  .text {
    max-width: initial;
  }
}

.installButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 6px;
  max-width: 100%;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .installButtons {
    justify-content: start;
    gap: 8px;
  }
}

@media only screen and (min-width: 1440px) {
  .installButtons {}
}

.installButton {
  color: #221F1F;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 6px 13px;
  border-radius: 35px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .installButton {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1440px) {
  .installButton {
    font-size: 16px;
  }
}

.installButton:hover {
  border-color: #3ACA79;
  transition: 0.3s;
}

.installButtonActive {
  border-color: #3ACA79;
}

.dot {
  background: #3aca79;
  min-width: 8px;
  min-height: 8px;
  max-width: 8px;
  max-height: 8px;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) {
  .dot {
    margin: 0px 10px;
  }
}

@media only screen and (min-width: 1440px) {
  .dot {
    margin: 0px 40px;
  }
}

.bigScreenDot {
  display: none;
}

@media only screen and (min-width: 768px) {
  .bigScreenDot {
    display: block;
  }
}

.installBtnAction {
  border: none;
  border-radius: 35px;
  background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
  font-style: normal;
  color: #ffffff;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 8px;
}


@media only screen and (min-width: 768px) {
  .installBtnAction {
    font-size: 14px;
    gap: 13px;
    padding: 12px 27px;
  }
}

@media only screen and (min-width: 1440px) {
  .installBtnAction {
    font-size: 16px;
    gap: 20px;
    padding: 16px 48px;
  }
}

.installBtnIcon {
  width: 21px;
  height: 21px;
}

@media only screen and (min-width: 768px) {
  .installBtnIcon {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (min-width: 1440px) {
  .installBtnIcon {
    width: 32px;
    height: 32px;
  }
}

.installBtnAction:hover {
  background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
  box-shadow: 0px 5px 10px rgba(81, 245, 153, 0.5);
}

.installBtnAction:active {
  background: #34a867;
  box-shadow: none;
}

.installBtnAction:disabled {
  background: #e5e5e5;
  color: #b3b3b3;
  cursor: not-allowed;
}

.installBtnAction:disabled:hover {
  box-shadow: none;
}

.reviewsWrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .reviewsWrapper {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1440px) {
  .reviewsWrapper {}
}

.stars {
  align-items: center;
  color: #3ACA79;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  /* height: 22px; */
  display: block;
  position: relative;
  width: fit-content;
}

.emptyStarts {
  display: flex;
  position: relative;
}

.fillStarts {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.starIcon {
  height: 21px;
  width: 21px;
}


@media only screen and (min-width: 768px) {
  .starIcon {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (min-width: 1440px) {
  .starIcon {
    width: 27px;
    height: 27px;
  }
}

.reviewsHeadline {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
  justify-content: center;
}


@media only screen and (min-width: 768px) {
  .reviewsHeadline {
    justify-content: space-between;
  }
}

/* @media only screen and (min-width: 1440px) {
  .reviewsHeadline {
    gap: 18px;
  }
} */

.reviewsCount {
  color: #221F1F;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px;
}

@media only screen and (min-width: 768px) {
  .reviewsCount {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1440px) {
  .reviewsCount {
    font-size: 20px;
  }
}

.reviewsBottomLine {
  color: #221F1F;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2px;
}

@media only screen and (min-width: 768px) {
  .reviewsBottomLine {
    font-size: 17px;
    margin-top: 4px;
  }
}

@media only screen and (min-width: 1440px) {
  .reviewsBottomLine {
    font-size: 20px;
    margin-top: 7px;
  }
}

.reviewsWrapper:hover .reviewsBottomLine {
  text-decoration: underline;
}

/* .reviewsWrapper:hover .reviewsCount {
  text-decoration: underline;
} */

.installAndReviewsLine {}

@media only screen and (min-width: 768px) {
  .installAndReviewsLine {
    margin-top: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1440px) {
  .installAndReviewsLine {
    margin-top: 16px;
  }
}

.imageMaxW {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

.imageContainer {
  display: flex;
  justify-content: center;
  min-width: 100%;
  font-size: 0;
  max-width: 1920px;
  /* width: 100vw; */
}

.imageContainer:before {
  content: "";
  padding-top: calc(100% / 1.777777777777778);
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.imageContainer>* {
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
}

.image {
  position: relative;
  /* overflow: hidden; */
  font-size: 0;
  -webkit-animation: image .75s .5s both;
  animation: image .75s .5s both;
}

.image .vvPlaceholder {
  opacity: 0;
}

@keyframes image {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.imageWrap {
  width: 1300px;
}

.vvWrapper {
  background-color: rgba(0, 0, 0, 0.2);
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.vvAsset {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}

.vvVideo {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.imageMaxW {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .imageMaxW {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1440px) {
  .imageMaxW {
    margin-top: 120px;
  }
}

.controlButton,
.fullscreenButton {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border: none;
}

.fullscreenButton {
  background: url(./img/any/full-screen.svg) no-repeat center center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}

.fullscreenButton:focus {
  outline: none;
}

.controlButton:focus {
  outline: none;
}


.controlButtonPlay {
  background: url(./img/any/play.svg) no-repeat center center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}

.controlButtonPause {
  background: url(./img/any/pause-white.svg) no-repeat center center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}

.controlButtonWrapper {
  position: relative;
  /* bottom: 2vh; */
  background-color: white;
  color: white;
  padding: 6px 13px;
  cursor: pointer;
  z-index: 10;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.controlButtonWrapperMobile {
  position: relative;
  /* bottom: 2vh; */
  background-color: white;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

@media only screen and (min-width: 768px) {
  .controlButtonWrapper {
    /* margin-top: 80px; */

  }
}

@media only screen and (min-width: 1440px) {
  .controlButtonWrapper {
    position: absolute;
    bottom: 2vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: unset;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    margin-top: 2px;
  }

  .controlButtonWrapper:hover {
    background-color: rgba(0, 0, 0, 0.7);
    transform: translateX(-50%) scale(1.2);
  }
}



.timelineButtons {
  position: relative;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: white;
}

@media only screen and (min-width: 768px) {
  .timelineButtons {
    /* margin-top: 80px; */

  }
}

@media only screen and (min-width: 1440px) {
  .timelineButtons {
    position: absolute;
    top: 2vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: unset;
    height: fit-content;
  }
}

.timelineBtn {
  cursor: pointer;
  color: #221F1F;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  border-radius: 35px;
  padding: 4px 12px;
  background: linear-gradient(to right, rgba(58, 202, 121, 0.5) 0%, rgba(58, 202, 121, 0.5) 50%, rgba(255, 255, 255, 0.50) 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 1s linear;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 10%);
  user-select: none;
}

@media only screen and (min-width: 768px) {
  .timelineBtn {
    font-size: 14px;
    padding: 5px 14px;
  }
}

@media only screen and (min-width: 1440px) {
  .timelineBtn {
    font-size: 16px;
    padding: 6px 16px;
  }
}