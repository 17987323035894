.section {
    margin-top: 20px;
    display: flex;
}

@media only screen and (min-width: 768px) {
    .section {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 1440px) {
    .section {
        margin-top: 40px;
    }
}

.input {
    width: 100%;
    height: 40px;
    border: 1px solid rgb(34 31 31 / 50%);
    padding: 10px 20px;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f;
    outline: none;
    border-radius: 4px 0px 0px 4px;
    border-right: none;
}

@media only screen and (min-width: 768px) {
    .input {
        height: 41px;
    }
}

@media only screen and (min-width: 1440px) {
    .input {
        height: 45px;
    }
}

.button {
    width: 94px;
    height: 40px;
    background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
    border-radius: 0px 4px 4px 0px;
    border: none;
    padding: 13px 25px;
    color: white;
    font-weight: var(--font-weight-10-bold);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    text-transform: uppercase;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .button {
        padding: 12px 25px;
        width: 103px;
        height: 41px;
    }
}

@media only screen and (min-width: 1440px) {
    .button {
        padding: 13px 30px;
        width: 121px;
        height: 45px;
    }
}
