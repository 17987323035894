.cardWithScreenshotContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 25px;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .cardWithScreenshotContainer {
        margin-top: 15px;
    }
}

@media only screen and (min-width: 1440px) {
    .cardWithScreenshotContainer {
        flex-direction: row;
        margin-top: 20px;
    }
}

.leftNavigatorIconWrapper1 {
    position: absolute;
    top: calc(50% + 50px);
    left: -60px;
}

.screenShotWrapper {
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .screenShotWrapper {
        width: 75%;
    }
}

@media only screen and (min-width: 1440px) {
    .screenShotWrapper {
        height: 500px;
        display: flex;
        align-items: center;
        align-content: center;
        flex: 4 0 675px;
    }
}

.image {
    content: url(./img/any/choose.webp);
    width: 100%;
    box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
    border-radius: 10px;
}

.chooseImg {
    content: url(./img/any/choose.webp);
}

.popupImg {
    content: url(./img/any/popup.webp);
}

@media only screen and (min-width: 768px) {
    .image {
        /* content: url(./img/medium/screenshot.png); */
        /* max-width: 550px; */
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1440px) {
    .image {
        margin-top: 20px;
        /* content: url(./img/big/choose.jpg); */
    }
}

.description {
    display: inline-block;
}

@media only screen and (min-width: 768px) {
    .description {
    }
}

@media only screen and (min-width: 1440px) {
    .description {
        margin-left: 40px;
        max-width: 49%;
    }
}
