.arrow {
    background-image: url(./arrow.svg);
    width: 9.72px;
    height: 10px;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
}

.btn {
    border: 1px solid #3aca79;
    border-radius: 4px;
    padding: 12px 25px 12px 25px;
    text-transform: uppercase;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    color: #3aca79;
    font-weight: var(--font-weight-10);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
}

.btn:hover {
    border: 1px solid #3ACA79;
    box-shadow: 0px 5px 10px rgba(81, 245, 153, 0.5);
}

.btn:active {
    color: #34A867;
    border: 1px solid #34A867;
    box-shadow: none;
}