.body {
    margin-top: 10px;
}

@media only screen and (min-width: 660px) {
    .body {
        margin-top: 20px;
        display: flex;
    }
}

@media only screen and (min-width: 1440px) {
    .body {
        margin-top: 30px;
    }
}

.needLoadingGoods {
    width: 100%;
    height: 100px;
}

.goodsWrapper {
    width: 100%;
}

@media only screen and (min-width: 906px) {
    .goodsWrapper {
        width: 75%;
    }
}