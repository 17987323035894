.header {
    display: block;
    margin: 0 auto;
    font-style: normal;
    font-weight: var(--font-weight-22);
    font-size: var(--font-size-22);
    line-height: var(--line-height-22);
    text-align: center;
    color: #221f1f;
    text-decoration: underline;
    text-decoration-color: #3aca79;
    text-underline-position: under;
}

.subHeader {
    display: block;
    margin: 0 auto;
    font-style: normal;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    text-align: center;
    color: #221f1f;
    margin-top: 10px;
}

.sectionDivine {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100px;
    transform: translate(-50%, 0px);
    left: 50%;
    top: -10px;
    /* border-radius: 12px; */
    background: linear-gradient(360deg, white 0%, #f1f1f1 100%);
    z-index: -1;
}

@media only screen and (min-width: 768px) {
    .sectionDivine {
        top: -25px;
    }
}

@media only screen and (min-width: 1440px) {
    .sectionDivine {
        top: -30px;
    }
}

.section {
    margin-top: 40px;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .section {
        margin-top: 100px;
    }
}

@media only screen and (min-width: 1440px) {
    .section {
        margin-top: 200px;
    }
}

.cost {
}

.costImage {
    background: url(./img/small/cost.png) center center/cover no-repeat;
    width: 69px;
    height: 69px;

    box-shadow: 0px 0px 50px -12px rgb(81 245 153 / 53%);
    border-radius: 50%;
    background-color: rgb(81 245 153 / 7%);
}

@media only screen and (min-width: 768px) {
    .costImage {
        background: url(./img/medium/cost.png) center center/cover no-repeat;
        width: 120px;
        height: 120px;
        box-shadow: initial;
        border-radius: initial;
        background-color: initial;
    }
}

@media only screen and (min-width: 1440px) {
    .costImage {
        background: url(./img/big/cost.png) center center/cover no-repeat;
        width: 140px;
        height: 140px;

        box-shadow: initial;
        border-radius: initial;
        background-color: initial;
    }
}

.content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

@media only screen and (min-width: 768px) {
    .content {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 1440px) {
    .content {
        margin-top: 40px;
    }
}


.title {
    font-weight: var(--font-weight-16);
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    color: #221f1f;
}

.text {
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    color: #221f1f;
    margin-top: 10px;
}

.part2Wrapper {
    min-width: 110px;
}