.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.title {
  font-weight: var(--font-weight-16-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-16);
  margin: 40px 0 15px 0;
}

.wrapper {
  width: 80%;
}
