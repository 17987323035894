.registrationContainer {
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 30px;

}

@media only screen and (min-width: 768px) {
    .registrationContainer {
        margin-top: 40px;
    }
}

@media only screen and (min-width: 1440px) {
    .registrationContainer {
        margin-top: 60px;
    }
}

.registrationHeader {
    margin: 22px 0;
    text-align: center;
    font-size: var(--font-size-24);
    line-height: var(--line-height-24);
    font-weight: var(--font-weight-24-bold);
}

@media only screen and (min-width: 768px) {
    .registrationHeader {
        margin: 25px 0;
    }
}

@media only screen and (min-width: 1440px) {
    .registrationHeader {
        margin: 30px 0;
    }
}

.inputWrap {
    position: relative;
}



.registrationBtns {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.truePricePopup__input {
    cursor: pointer;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
}

label {
    position: relative;
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    font-weight: var(--font-weight-10);
    color: #221f1f;
}


input[type='checkbox'].truePricePopup__input+label:before {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 20px;
    left: -25px;
    position: absolute;
    top: -3px;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    width: 20px;
}

@media only screen and (min-width: 768px) {
    input[type='checkbox'].truePricePopup__input+label:before {
        top: -2px;
    }
}

@media only screen and (min-width: 1440px) {
    input[type='checkbox'].truePricePopup__input+label:before {
        top: 0;
    }
}

input[type='checkbox'].truePricePopup__input:checked+label:before {
    background-image: url('./img/checkbox-active.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    border-color: #00a046;
}

.btnWrapper {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    width: 100%;
}

