.imageWrapper {
    position: relative;
    display: block;
    margin-bottom: 8px;
    overflow: hidden;
    min-width: 150px;
    min-height: 150px;
    overflow: hidden;
}

@media only screen and (min-width: 768px) {
    .imageWrapper {
        min-width: 250px;
        min-height: 250px;
    }
}

@media only screen and (min-width: 1440px) {
    .imageWrapper {
        min-width: 400px;
        min-height: 400px;
    }
}

.img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    z-index: -1;
}

.goodWrapper {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 100%;
    height: 100%;
    padding: 0 16px;
    column-gap: 10px;
}

@media only screen and (min-width: 768px) {
    .goodWrapper {
        /* flex-direction: row; */
        margin-top: 60px;
        /* column-gap: 20px; */
    }
}

@media only screen and (min-width: 1440px) {
    .goodWrapper {
        margin-top: 80px;
        /* column-gap: 120px; */
    }
}

.tableTitle {
    font-weight: 700;
    font-size: var(--font-size-20);
    line-height: var(--line-height-20);
    margin-top: 40px;
    text-align: center;
    width: 100%;
    /* text-decoration: underline;
    text-decoration-color: #3aca79;
    text-underline-position: under; */
}

@media only screen and (min-width: 768px) {
    .tableTitle {
        margin-top: 60px;
    }
}

@media only screen and (min-width: 1440px) {
    .tableTitle {
        margin-top: 80px;
    }
}

.name {
    /* font-weight: var(--font-weight-16); */
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    /* margin-bottom: 10px; */
    /* text-align: center; */
}

/* @media only screen and (min-width: 768px) {
    .name {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .name {
        margin-bottom: 30px;
    }
} */

.discount {
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
}

.discount>span {
    font-weight: 700;
}


.price {
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
}

.price>span {
    color: #3aca79;
    font-weight: var(--font-weight-14-bold);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
}



.greenColor {
    color: #3aca79;
}

.descriptionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    gap: 10px;
}


@media only screen and (min-width: 768px) {
    .descriptionWrapper {
        gap: 15px;
    }
}

@media only screen and (min-width: 1440px) {
    .descriptionWrapper {
        gap: 20px;
    }
}

.loader {
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.goodTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
}

@media only screen and (min-width: 768px) {
    .goodTable {
        /* flex-direction: row; */
        margin-top: 60x;
        column-gap: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodTable {
        margin-top: 80px;
        column-gap: 30px;
    }
}


.goodTable th {
    background-color: #3aca79;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    vertical-align: text-top;
    /* padding: 35px 0px; */
}

.tableHead {
    min-width: 80px;
}

.tableHeadIcon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
    vertical-align: baseline;

}

@media only screen and (min-width: 768px) {
    .tableHeadIcon {
        width: 16px;
        height: 14px;
        margin-right: 8px;
         vertical-align: baseline;
    }
}

@media only screen and (min-width: 1440px) {
    .tableHeadIcon {
        width: 19px;
        height: 18px;
        margin-right: 12px;
        vertical-align: text-top;
    }
}


@media only screen and (min-width: 768px) {
    .goodTable th {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodTable th {
        font-size: 18px;
    }
}

.goodTable td {
    padding: 15px 10px 15px 10px;
    /* border: 1px solid #ddd; */
    text-align: left;
}

tr {
    border-bottom: 1px solid #e8e8e8;
}

.goodTable th {
    padding: 20px 10px;
    /* border: 1px solid #ddd; */
    text-align: left;
}

/* .goodTable tr:nth-child(even) {
    background-color: #f2f2f2;
} */

.goodButton {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 16px;
    transition: all 0.15s ease;
    border-radius: 30px;
    border: 1px solid #3ACA79;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: right;
}

@media only screen and (min-width: 768px) {
    .goodButton {
        padding: 10px 24px;
        font-size: 14px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodButton {
        font-size: 16px;
        padding: 14px 44px;
    }
}

.goodTable .tdGoodButton {
    text-align: right;
}

.goodButton:hover {
    background: #3ACA79;
    color: white;
}

.goodTable .tableProductName {
    /* text-align: left; */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #221F1F;
}

@media only screen and (min-width: 768px) {
    .goodTable .tableProductName {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodTable .tableProductName {
        font-size: 16px;
    }
}

.goodTable .tablePrice {
    color: #221F1F;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* display: flex;
    align-items: center;
    justify-content: flex-start; */
    gap: 4px;
    /* height: 100%; */
    text-align: left;
}

@media only screen and (min-width: 768px) {
    .goodTable .tablePrice {
        font-size: 16px;
        gap: 8px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodTable .tablePrice {
        font-size: 16px;
        gap: 13px;
    }
}


.goodTable .tablePriceIcon {
    margin-left: 4px;
    vertical-align: revert;
}

@media only screen and (min-width: 768px) {
    .goodTable .tablePriceIcon {
        margin-left: 8px;
    }
}

@media only screen and (min-width: 1440px) {
    .goodTable .tablePriceIcon {
        margin-left: 13px;
    }
}

.showGraph {
    width: fit-content;
    margin-top: 1px;
}

@media only screen and (min-width: 768px) {
    .showGraph {
        margin-top: 2.5px;
    }
}

@media only screen and (min-width: 1440px) {
    .showGraph {
        margin-top: 5px;
    }
}



tr {
    width: min-content;
}

.productNameTitle {
    font-weight: 700;
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    /* margin-bottom: 10px; */
}