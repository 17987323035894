.title {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    font-weight: var(--font-weight-16-bold);
}

.text > span {
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    font-weight: var(--font-weight-14-bold);
}

.text > p {
    margin-top: 30px;
    margin-bottom: 20;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    font-weight: var(--font-weight-12-bold);
    font-style: italic;
}

.mr100 {
    margin-left: 100px;
}

.mr50 {
    margin-left: 50px;
}

.text > a {
    color: #3ACA79;
}