.settingsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.settingsTelegramTitle {
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    font-weight: var(--font-weight-12-bold);
}
.settingsBtnWrap {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    text-transform: none;
    font-style: normal;
    font-weight: var(--font-weight-10);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    height: 140px;
    display: flex;
    width: 260px;
    align-items: center;
    flex-direction: column;
    padding: 15px;
}

@media only screen and (min-width: 768px) {
    .settingsBtnWrap {
        width: 335px;
    }
}

@media only screen and (min-width: 1440px) {
    .settingsBtnWrap {
        width: 335px;
    }
}
.settingsButtonCover {
    margin-bottom: 10px;
}
.settingsButton {
    width: 125px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-weight-10-bold);
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    border: none;
    border-radius: 4px;
    background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
    font-style: normal;
    color: #ffffff;
    height: 35px;
    padding: 8px 25px;
}

@media only screen and (min-width: 768px) {
    .settingsButton {
        width: 150px;
        padding: 12px 25px;
    }
}

@media only screen and (min-width: 1440px) {
    .settingsButton {
        width: 150px;
        padding: 12px 25px;
    }
}

.settingsImg {
    content: url('./img/any/telegram.svg');
    margin-right: 5px;
    width: 24px;
    height: 24px;
}

.settingsTogleContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    margin-top: 10px;
}

.settingsButton:hover {
    background: linear-gradient(94.52deg, #3aca79 0%, #51f599 100%);
    box-shadow: 0px 5px 10px rgba(81, 245, 153, 0.5);
}

.settingsButton:active {
    background: #34a867;
    box-shadow: none;
}

.settingsButton:disabled {
    background: #e5e5e5;
    color: #b3b3b3;
    cursor: not-allowed;
}

.settingsButton:disabled:hover {
    box-shadow: none;
}

.orangeBtn {
    background: linear-gradient(94.52deg, #e36810, #ffc890);
}

.orangeBtn:hover {
    background: linear-gradient(94.52deg, #e36810, #ffc890);
    box-shadow: 0px 5px 10px rgba(255, 144, 144, 0.5);
}

.chekbox__input {
    display: none;
}
.checkbox + label {
    cursor: pointer;
    display: inline-block;
    padding-left: 24px;
    position: relative;
}

.checkbox + label:before {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: -10px;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    width: 20px;
}

.checkbox:checked + label:before {
    background-image: url('./img/any/checkbox-active.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    border-color: #00a046;
}

input[type='checkbox'].chekbox__input + label:before {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 20px;
    left: 105%;
    position: absolute;
    top: -1px;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    width: 20px;
}

@media only screen and (min-width: 768px) {
    input[type='checkbox'].chekbox__input + label:before {
        top: -2px;
    }
}

@media only screen and (min-width: 1440px) {
    input[type='checkbox'].chekbox__input + label:before {
        top: 2px;
    }
}

input[type='checkbox'].chekbox__input:checked + label:before {
    background-image: url('./img/any/checkbox-active.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    border-color: #00a046;
}

.notificationChekbox__input {
    display: none;
}

.chekbox__lable {
    position: relative;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    font-weight: var(--font-weight-12);
    color: #221f1f;
    cursor: pointer;
    margin-right: 15px;
}
