.arrow {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
    color: #3aca79;
    content: url('./img/any/arrow.svg');
    padding: 12px;
}

.arrow:disabled {
    opacity: 0.5;
}

.arrowRight {
    transform: rotate(180deg);
}

.paginationItem {
    cursor: pointer;
    border: none;
}

.pageNumber {
    font-weight: 300;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
}

.selected {
    color: #3aca79;
}

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

@media only screen and (min-width: 768px) {
    .pagination {
        justify-content: flex-end;
    }
}