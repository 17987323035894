.section {
    padding-right: 10px;
    padding-left: 10px;
}

@media only screen and (min-width: 1440px) {
    .section {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.title {
    font-weight: var(--font-weight-16-bold);
    font-size: var(--font-size-16);
    line-height: var(--line-height-16);
    text-align: center;
}

.subtitle {
    margin-top: 15px;
    font-weight: var(--font-weight-12);
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
}

.notificationsWrapper {
    display: flex;
    flex-direction: column;
}

.card {
    width: 100%;
    margin-top: 10px;
}

.cardBody {
    background: #f8f8f8;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    align-items: stretch;
}

.cardButtons {
    background: #eeeeee;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    padding: 5px;
}

.checkboxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkbox {
    appearance: none;
    border: 0;
    position: absolute;
    cursor: pointer;
    margin-right: 5px;
    width: 20px;
    height: 20px;
}

.checkbox + label {
    cursor: pointer;
    display: inline-block;
    padding-left: 24px;
    position: relative;
}

.checkbox + label:before {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: -10px;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    width: 20px;
}

.checkbox:checked + label:before {
    background-image: url('./img/any/checkbox-active.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    border-color: #00a046;
}

.goodName {
    font-weight: 400;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    white-space: nowrap;
    color: #221f1f;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    border-bottom: 1px solid #3aca79;
    outline: none;
    border-radius: 0px;
}

.goodName:disabled {
    border-bottom: none;
}

.goodPrice {
    font-weight: 700;
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #3aca79;
}

.discount {
    background: #f84147;
    border-radius: 14px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    color: #ffffff;
    margin-left: 10px;
}

.imgWrapper {
    display: flex;
    justify-content: center;
    flex: 0 0 25%;
    max-height: 115px;
}

.img {
    max-width: 100%;
    max-height: 100%;
}

.discountWrapper {
    display: flex;
    align-items: center;
}

.goodInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
    flex: 1 0 auto;
    width: 25%;
}

.onRozetkaText {
    font-weight: var(--font-weight-14);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #221f1f;
    text-decoration-line: underline;
}

.settings {
    content: url('./img/any/settings.svg');
    cursor: pointer;
}

.remove {
    content: url('./img/any/remove.svg');
    cursor: pointer;
}

.notificationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notificationWrapper {
    width: 100%;
    background-color: #f8f8f8;
    padding: 25px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    .notificationWrapper {
        padding: 10px;
    }
}

@media only screen and (min-width: 1440px) {
    .notificationWrapper {
        padding: 10px;
    }
}

.notificationPayments {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media only screen and (min-width: 768px) {
    .notificationPayments {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

@media only screen and (min-width: 1440px) {
    .notificationPayments {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
}

.notificationPrice {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
    justify-content: center;
}

.notificationPaymentTitle {
    font-weight: var(--font-weight-14-bold);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #221f1f;
}

.notificationChekbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 20px 0 5px;
}

@media only screen and (min-width: 768px) {
    .notificationChekbox {
        margin-right: 0px;
    }
}

@media only screen and (min-width: 1440px) {
    .notificationChekbox {
        margin-right: 0px;
    }
}

.arrow {
    border: solid #3aca79;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;
    transform: rotate(45deg);
    transition: transform 0.5s;
}

.notificationImgPrivate {
    content: url(./img/any/private.svg);
    margin: 0 10px;
}

.notificationImgMono {
    content: url(./img/any/mono.svg);
    margin: 0 10px;
}

.input {
    width: 60px;
    height: 30px;
    padding: 5px;
    text-align: center;
    line-height: 20px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #221f1f;
    margin-left: 10px;
}

.notification_check {
    margin-right: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.notification_btns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;
    margin-left: 9%;
}

@media only screen and (min-width: 768px) {
    .notification_btns {
        justify-content: space-evenly;
    }
}

@media only screen and (min-width: 1440px) {
    .notification_btns {
        justify-content: space-evenly;
        width: 65%;
    }
}

.notificationChekbox__input,
.notificationChekboxSocial__input {
    display: none;
}

.notificationChekbox__lable {
    position: relative;
    font-size: var(--font-size-12);
    line-height: var(--line-height-12);
    font-weight: var(--font-weight-12);
    color: #221f1f;
    cursor: pointer;
}

.percent {
    font-weight: var(--font-weight-14-bold);
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    color: #221f1f;
}

input[type='checkbox'].notificationChekbox__input + label:before {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 20px;
    left: -25px;
    position: absolute;
    top: -1px;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    width: 20px;
}

@media only screen and (min-width: 768px) {
    input[type='checkbox'].notificationChekbox__input + label:before {
        top: -2px;
    }
}

@media only screen and (min-width: 1440px) {
    input[type='checkbox'].notificationChekbox__input + label:before {
        top: 2px;
    }
}

input[type='checkbox'].notificationChekbox__input:checked + label:before {
    background-image: url('./img/any/checkbox-active.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    border-color: #00a046;
}

input[type='checkbox'].notificationChekboxSocial__input + label:before {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 20px;
    left: -25px;
    position: absolute;
    top: -2px;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    width: 20px;
}

@media only screen and (min-width: 768px) {
    input[type='checkbox'].notificationChekboxSocial__input + label:before {
        top: -2px;
    }
}

@media only screen and (min-width: 1440px) {
    input[type='checkbox'].notificationChekboxSocial__input + label:before {
        top: 0px;
    }
}

input[type='checkbox'].notificationChekboxSocial__input:checked + label:before {
    background-image: url('./img/any/checkbox-active.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    border-color: #00a046;
}

.separator {
    width: 2px;
    min-height: 80%;
    margin: 0 10px;
    background-color: #3aca79;
}

.packetUnsubscribeButton {
    border: none;
    font-size: 300;
    line-height: var(--line-height-12);
    font-weight: var(--font-weight-12);
    text-decoration-line: underline;
    color: #221f1f;
    cursor: pointer;
}

.packetUnsubscribeButtons {
    display: flex;
}

.paginationAndRemoveWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-top: 20px;
}

@media only screen and (min-width: 425px) {
    .paginationAndRemoveWrapper {
        flex-direction: row;
    }
}

.noMessages {
    margin: 20px auto 0 auto;
}

@media only screen and (min-width: 768px) {
    .noMessages {
        margin: 30px auto 0 auto;
    }
}

@media only screen and (min-width: 1440px) {
    .noMessages {
        margin: 40px auto 0 auto;
    }
}
