.popup_overlay {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000080;
    display: none;
}

.popup_overlay_active {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000080;
    display: block;
    z-index: 2;
}

.popup_container {
    white-space: break-spaces;
    position: absolute;
    background-color: #fff;
    max-width: 1440px;
    max-height: 95%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 20px;
    overflow: hidden;
}

.popup_close {
    cursor: pointer;
    position: absolute;
    top: 2%;
    right: 2%;
}

.popup_title,
.popup_MoreTitle {
    color: #221f1f;
    text-align: center;
    font-size: var(--font-size-18);
    line-height: var(--line-height-18);
    font-weight: var(--font-weight-18-bold);
    padding: 0px 30px;
}

.popup_title {
    margin-top: 20px;
}

.popup_MoreTitle {
    margin-top: 25px;
}

/* .popup_FuncDescription {
    width: 400px;
} */

.popup_prices {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.popup_FuncPrices {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    margin-top: 15px;
}

.popup_description,
.popup_FuncDescription {
    color: #221f1f;
    font-size: var(--font-size-14);
    line-height: var(--line-height-14);
    font-weight: 500;
    text-align: center;
    flex: 0.5 0 0;
}

.popup_image {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

/* .popup_image img {
    box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.1);
    width: 550px;
} */

.popup_FuncImage {
    max-width: 50%;
}

.popup_forFree {
    height: 20px;
    vertical-align: sub;
}

.popup_background {
    position: absolute;
    right: 493px;
    top: 116px;
    width: 412px;
    height: 464px;
    background: #3aca79;

    transform: rotate(-45deg);
    z-index: -1;
    display: block;
}

@media only screen and (min-width: 768px) {
    .popup_background {
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
    }
}

@media only screen and (min-width: 1440px) {
    .popup_background {
        right: 590px;
        top: 158px;
        width: 412px;
        height: 464px;
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
    }
}

@media only screen and (min-width: 1550px) {
    .popup_background {
        right: 925px;
        top: 38px;
        width: 912px;
        height: 912px;
    }
}

.popup_span {
    color: #3ACA79;
    font-weight: 700;
}

.image_chart {
    content: url(./img/any/prices.webp);
    width: 80%;
    box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1);
    border-radius: 10px;
}


.image {
    content: url(./img/any/functions.webp);
    width: 100%;
    /* box-shadow: 0px 0px 20px rgba(34, 31, 31, 0.1); */
    border-radius: 10px;
}

.btn_wrapper {
    display: none;
}

@media only screen and (min-height: 900px) {
    .btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
